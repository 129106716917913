import classes from "./NavToggle.module.css";

import toggle from "../../../assets/vectors/toggle.svg";

import { motion } from "framer-motion";
import { forwardRef } from "react";

const NavToggle = forwardRef(({ callback }, ref) => {
  return (
    <button ref={ref} onClick={callback}>
      <img
        src={toggle}
        alt="toggle"
        className={`${classes.nav_toggle} inline-block w-11 object-cover`}
      />
    </button>
  );
});

export default NavToggle;

export const MNavToggle = motion(NavToggle);
