import FooterItem from "./FooterItem/FooterItem";
import { useSelector } from "react-redux";

const Footer = () => {
  const user = useSelector((state) => state.user);

  return (
    <footer className="Footer py-5 text-blue-gray-600">
      <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
        <p className="block antialiased font-sans text-sm leading-normal font-normal text-inherit">
          © 2023, SmartBox
        </p>
        <ul className="flex items-center gap-4">
          <FooterItem url="/">Главная</FooterItem>
          {user && user.status === "approve" && (
            <>
              <FooterItem url="/history">История</FooterItem>
              <FooterItem url="/wallet">Баланс</FooterItem>
              <FooterItem url="/about">О Нас</FooterItem>
            </>
          )}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
