import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: null,
  reducers: {
    setAdmin: (state, action) => {
      return action.payload;
    },
    clearAdmin: (state) => {
      return null;
    },
  },
});

export const setAdmin = (admin) => {
  return (dispatch) => {
    const adminData = {
      username: admin.username,
      userId: admin.userId,
      email: admin.email,
      name: admin.name,
      status: admin.status,
      balance: Number(admin.balance),
    };
    dispatch({ type: "admin/setAdmin", payload: adminData });
  };
};

export const clearAdmin = () => {
  return { type: "user/clearUser" };
};

export default adminSlice.reducer;
