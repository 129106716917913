import { MdClose } from "react-icons/md";
import BarcodeCard from "../../BarcodeCard/BarcodeCard";

const CodePopup = ({
  selectedCellCode,
  selectedCellData,
  barcodeCardRef,
  closePopup,
  handlePrintContent,
}) => {
  return (
    selectedCellData && (
      <div className="fixed top-5 right-5 md:top-10 md:right-10 bg-white shadow-md rounded-lg z-50">
        <div className="relative pt-10 pb-5 px-8">
          <div className="border border-black">
            <BarcodeCard
              selectedCellCode={selectedCellCode}
              selectedCellData={selectedCellData}
              ref={barcodeCardRef}
            />
            <button
              className="absolute top-2 right-2 block p-1 bg-red-500 text-white rounded-md cursor-pointer"
              onClick={closePopup}
            >
              <MdClose />
            </button>
          </div>
        </div>
        <div className="text-center w-full pb-6">
          <button
            className="block p-2 mx-auto bg-blue-500 text-white rounded-md cursor-pointer"
            onClick={handlePrintContent}
          >
            Распечатать
          </button>
        </div>
      </div>
    )
  );
};

export default CodePopup;
