import { Route, Routes } from "react-router-dom";
import "./App.css";

import AdminPrivateRoute from "./components/admin_components/AdminPrivateRoute/AdminPrivateRoute";
import PrivateRoute from "./components/client_components/PrivateRoute/PrivateRoute";

import Admin from "./pages/root_pages/Admin";
import Dashboard from "./pages/root_pages/Dashboard";
import User from "./pages/root_pages/User";

import ClientHome from "./pages/client_pages/ClientHome";
import ClientHistory from "./pages/client_pages/ClientHistory";
import ClientWallet from "./pages/client_pages/ClientWallet";
import ClientAuth from "./pages/client_pages/ClientAuth";
import ClientTable from "./pages/client_pages/ClientTable";

import AdminAuth from "./pages/admin_pages/AdminAuth";
import SmartBox from "./pages/admin_pages/SmartBox";
import ApplicationInspect from "./pages/admin_pages/ApplicationInspect";
import SmartboxSizes from "./pages/admin_pages/SmartboxSizes";
import AdminHome from "./pages/admin_pages/AdminHome";
import AdminHistory from "./pages/admin_pages/AdminHistory";
import AdminWallet from "./pages/admin_pages/AdminWallet";
import AdminTable from "./pages/admin_pages/AdminTable";

import UserHome from "./pages/user_pages/UserHome";
import About from "./pages/user_pages/About";

import SmartLink from "./pages/common_pages/SmartLink";
import NotFound from "./pages/common_pages/NotFound";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/auth" element={<ClientAuth />} />
        <Route path="/admin" element={<AdminAuth />} />
        <Route path="/s" element={<SmartLink />} />
        <Route path="/*" element={<PrivateRoute element={<NotFound />} />} />

        <Route path="/" element={<User />}>
          <Route path="" element={<UserHome />} />
          <Route path="about" element={<About />} />
        </Route>

        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="" element={<PrivateRoute element={<ClientHome />} />} />
          <Route
            path="table"
            element={<PrivateRoute element={<ClientTable />} />}
          />
          <Route
            path="history"
            element={<PrivateRoute element={<ClientHistory />} />}
          />
          <Route
            path="wallet"
            element={<PrivateRoute element={<ClientWallet />} />}
          />
        </Route>

        <Route
          path="/adminpanel"
          element={<AdminPrivateRoute element={<Admin />} />}
        >
          <Route
            path=""
            element={<AdminPrivateRoute element={<AdminHome />} />}
          />
          <Route
            path="table"
            element={<AdminPrivateRoute element={<AdminTable />} />}
          />
          <Route
            path="history"
            element={<AdminPrivateRoute element={<AdminHistory />} />}
          />
          <Route
            path="wallet"
            element={<AdminPrivateRoute element={<AdminWallet />} />}
          />
          <Route
            path="smartbox"
            element={<AdminPrivateRoute element={<SmartBox />} />}
          />
          <Route
            path="smartboxsizes"
            element={<AdminPrivateRoute element={<SmartboxSizes />} />}
          />
          <Route
            path="application-inspect"
            element={<AdminPrivateRoute element={<ApplicationInspect />} />}
          />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
