import React, { useState } from "react";
import ReportTable from "../../components/client_components/ReportTable/ReportTable";
import StatisticCardGroup from "../../components/client_components/StatisticCardGroup/StatisticCardGroup";
import { useSelector } from "react-redux";
import ApplicationForm from "../../components/client_components/ApplicationForm/ApplicationForm";

const ClientHome = () => {
  const [report, setReport] = useState(null);
  const user = useSelector((state) => state.user);

  return (
    <div>
      {user && user.status === "approve" ? (
        <>
          <StatisticCardGroup report={report} />
          <ReportTable onReport={setReport} />
        </>
      ) : (
        <ApplicationForm />
      )}
    </div>
  );
};

export default ClientHome;
