import { FaMoneyBillWave } from "react-icons/fa";
import { BiSolidInbox } from "react-icons/bi";
import { MdOutlineMonetizationOn } from "react-icons/md";
import StatisticCard from "./StatisticCard/StatisticCard";
import { useSelector } from "react-redux";

const StatisticCardGroup = ({ report }) => {
  const user = useSelector((state) => state.user);

  const smallData =
    report && report.small ? report.small : { count: 0, totalAmount: 0 };
  const mediumData =
    report && report.medium ? report.medium : { count: 0, totalAmount: 0 };
  const bigData =
    report && report.big ? report.big : { count: 0, totalAmount: 0 };

  const totalAmout =
    smallData.totalAmount + mediumData.totalAmount + bigData.totalAmount;

  return (
    <div className="my-4">
      <div className="grid gap-y-10 gap-x-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 hd:grid-cols-5">
        <StatisticCard
          icon={<FaMoneyBillWave className="w-[22px] h-[22px]" />}
          bgColor="blue"
          title="Баланс"
          text={`$ ${user.balance}`}
          percentage="+3% чем в прошлом месяце"
        />
        <StatisticCard
          icon={<BiSolidInbox className="w-[19px] h-[19px]" />}
          bgColor="pink"
          title="Маленький"
          text={`Кол-во: ${smallData.count}`}
          percentage="+3% чем в прошлом месяце"
        />
        <StatisticCard
          icon={<BiSolidInbox className="w-[22px] h-[22px]" />}
          bgColor="green"
          title="Средний"
          text={`Кол-во: ${mediumData.count}`}
          percentage="+3% чем в прошлом месяце"
        />
        <StatisticCard
          icon={<BiSolidInbox className="w-[25px] h-[25px]" />}
          bgColor="orange"
          title="Большой"
          text={`Кол-во: ${bigData.count}`}
          percentage="+3% чем в прошлом месяце"
        />
        <StatisticCard
          icon={<MdOutlineMonetizationOn className="w-[25px] h-[25px]" />}
          bgColor="gray"
          title="Итого"
          text={`$ ${totalAmout}`}
          percentage="+3% чем в прошлом месяце"
          className="xl:col-span-2"
        />
      </div>
    </div>
  );
};

export default StatisticCardGroup;
