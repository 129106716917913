import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
// import { useState } from "react";

const Step2 = ({ formData, setFormData }) => {
  // const [file, setFile] = useState(null);

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const droppedFile = e.dataTransfer.files[0];
  //   setFile(droppedFile);
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const errorText = document.getElementById("file-error-text");

    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // Файл превышает 5 МБ
        errorText.innerText = "Слишком большой файл. Макс. размер: 5 MB";
      } else {
        errorText.innerText = "";

        if (file.type.startsWith("image/")) {
          setFormData((prevData) => ({ ...prevData, companyLogo: file }));
          const objectURL = URL.createObjectURL(file);
          setFormData((prevData) => ({
            ...prevData,
            companyLogoUrl: objectURL,
          }));
        } else {
          setFormData((prevData) => ({ ...prevData, document: file }));
          const objectURL = URL.createObjectURL(file);
          setFormData((prevData) => ({ ...prevData, documentUrl: objectURL }));
        }
      }
    } else {
      errorText.innerText = "";
      setFormData((prevData) => ({ ...prevData, companyLogoUrl: null }));
      setFormData((prevData) => ({ ...prevData, documentUrl: null }));
    }
  };

  return (
    <div className="text-xl font-semibold mb-4">
      Шаг 2: Контактная информация
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-slate-100 rounded-lg overflow-hidden flex w-full">
          <div className="md:flex justify-center items-center p-3 w-full">
            <div className="w-32 h-32 bg-gray-500 rounded-full overflow-hidden mx-auto md:mx-0 md:mr-4">
              {formData.companyLogoUrl && (
                <img
                  src={formData.companyLogoUrl}
                  alt="Company Logo"
                  className="w-full h-full object-cover"
                />
              )}
            </div>
            <div className="md:flex-1 mt-3 md:mt-0 w-full">
              <div id="file-error-text" className="text-red-500"></div>
              <input
                type="file"
                name="companyLogo"
                accept="image/*"
                onChange={handleFileChange}
                id="companyLogo"
                required
                className="hidden"
              />
              <Button className="rounded-md cursor-pointer text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 w-full">
                <label htmlFor="companyLogo" className="block px-3 text-base">
                  Загрузить Логотип Компании
                </label>
              </Button>
            </div>
            {/* <div
                className="w-64 h-64 border-2 border-dashed border-gray-300 flex flex-col items-center justify-center"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <label
                  htmlFor="fileInput"
                  className="cursor-pointer w-full h-full flex items-center p-4"
                >
                  {file ? (
                    <div>
                      <p className="text-lg font-semibold">{file.name}</p>
                      <p className="text-sm text-gray-500">{file.size} bytes</p>
                    </div>
                  ) : (
                    <p className="text-2xl text-gray-500">
                      Перетащите файл сюда или нажмите, чтобы выбрать
                    </p>
                  )}
                </label>
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </div> */}
          </div>
        </div>

        <div className="bg-slate-100 rounded-lg overflow-hidden flex w-full">
          <div className="md:flex justify-center items-center p-3 w-full">
            <div className="w-32 h-32 bg-gray-500 rounded-full overflow-hidden mx-auto md:mx-0 md:mr-4">
              {formData.documentUrl && (
                <Link
                  to={formData.documentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="w-full h-full object-cover flex items-center justify-center">
                    <span className="text-white">Документ</span>
                  </div>
                </Link>
              )}
            </div>
            <div className="md:flex-1 mt-3 md:mt-0 w-full">
              <div id="document-error-text" className="text-red-500"></div>
              <input
                type="file"
                name="document"
                accept=".pdf, .doc, .docx"
                onChange={handleFileChange}
                id="document-upload"
                required
                className="hidden"
              />
              <Button className="rounded-md cursor-pointer text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 w-full">
                <label
                  htmlFor="document-upload"
                  className="block px-3 text-base"
                >
                  Загрузить документ
                </label>
              </Button>
            </div>
            {/* <div
              className="w-64 h-64 border-2 border-dashed border-gray-300 flex flex-col items-center justify-center"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <label
                htmlFor="fileInput"
                className="cursor-pointer w-full h-full flex items-center p-4"
              >
                {file ? (
                  <div>
                    <p className="text-lg font-semibold">{file.name}</p>
                    <p className="text-sm text-gray-500">{file.size} bytes</p>
                  </div>
                ) : (
                  <p className="text-2xl text-gray-500">
                    Перетащите файл сюда или нажмите, чтобы выбрать
                  </p>
                )}
              </label>
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
