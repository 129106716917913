import { useState } from "react";
import ReportTable from "../../components/admin_components/ReportTable/ReportTable";
import StatisticCardGroup from "../../components/admin_components/StatisticCardGroup/StatisticCardGroup";
import axios from "axios";

const AdminHome = () => {
  const [report, setReport] = useState(null);

  // async function test() {
  //   try {
  //     const data = {
  //       message: "Hello papa!",
  //     };
  //     const config = {
  //       headers: {
  //         Authorization: user.token,
  //       },
  //     };
  //     const res = await axios.post(
  //       "https://2dkm3l3tuc.execute-api.us-east-1.amazonaws.com/test",
  //       data,
  //       config
  //     );
  //     const messageData = res.data;
  //     console.log(messageData);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  async function freeUp() {
    try {
      const res = await axios.get(
        "https://2dkm3l3tuc.execute-api.us-east-1.amazonaws.com/test"
      );
      const messageData = res.data;
      console.log(messageData);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <StatisticCardGroup report={report} />
      <ReportTable onReport={setReport} />
      <button onClick={freeUp}>Free Up Cells</button>
    </div>
  );
};

export default AdminHome;
