import classes from "./Slide.module.css";
import Iframe from "react-iframe";

import smartbox from "../../../../assets/images/smartbox.webp";
import logo from "../../../../assets/logos/logo-2.svg";

const Slide = ({ id, name, address }) => {
  const api = "AIzaSyBrOTG10f6q_2b4rDS011y8rxSLL2vZ3mE";
  const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=${api}&q=${encodeURIComponent(
    address
  )}`;

  return (
    <div
      className={`${classes.Slide} bg-white lg:p-8 rounded-3xl max-w-[750px] w-full flex items-center lg:items-stretch justify-center lg:justify-between flex-col lg:flex-row`}
    >
      <div className="flex flex-col justify-between items-start lg:mr-2">
        <div
          className={`${classes.card_text} flex justify-center items-center -ml-8`}
        >
          <img
            src={smartbox}
            alt="smartbox"
            className={`${classes.box_image} max-w-[110px] lg:max-w-[156px]`}
          />
          <div>
            <img
              src={logo}
              alt="smartbox"
              className="w-full max-w-[150px] lg:max-w-[162px]"
            />
            <div className="bg-[#FB5012] rounded-full text-lg md:text-xl lg:text-2xl font-bold text-white inline-block px-3 mt-4">
              №{id}
            </div>
          </div>
        </div>
        <div className={`${classes.slide_text}`}>
          <h2 className="text-[#1C0448] lg:text-[28px] font-bold">{name}</h2>
          <p className="text-[#1C0448] lg:text-[18px]">{address}</p>
        </div>
      </div>
      <div
        className={`${classes.box_map} lg:max-w-[350px] w-full lg:max-h-[350px] bg-gray-500 rounded-[10px] lg:rounded-[20px] overflow-hidden`}
      >
        <Iframe url={mapsUrl} width="100%" height="100%" />
      </div>
    </div>
  );
};

export default Slide;
