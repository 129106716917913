import { motion } from "framer-motion";

import BoxesInfo from "./BoxesInfo/BoxesInfo";
import Partners from "./Partners/Partners";

import present from "../../../assets/images/present.webp";

const image_animation = {
  hidden: {
    x: -150,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.4 },
  }),
};

const SmartBoxInfo = () => {
  return (
    <section className="bg-[#1C0448] font-manrope mb-[-1px] pt-[80px] sm:pt-[210px]">
      <BoxesInfo />
      <Partners />

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.4, once: true }}
        className="w-full mt-[-150px] sm:mt-[-165px] relative z-[3]"
      >
        <motion.img
          variants={image_animation}
          custom={1}
          src={present}
          alt="present"
          className="max-w-[570px] w-full mx-auto"
        />
      </motion.div>
    </section>
  );
};

export default SmartBoxInfo;
