import { Input } from "@material-tailwind/react";
import { useState } from "react";

const Step1 = ({ formData, setFormData }) => {
  const [phone, setPhone] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    const formattedPhone = "+996" + numericValue;
    setPhone(numericValue);
    setFormData((prevData) => ({ ...prevData, phone: formattedPhone }));
  };

  return (
    <div>
      <div className="text-xl font-semibold mb-4">
        Шаг 1: Основная информация
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input
          label="Название Компании"
          name="company_name"
          value={formData.company_name}
          onChange={handleChange}
          required
        />
        <Input
          label="Имя"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          label="Адрес"
          name="address"
          value={formData.address}
          onChange={handleChange}
          required
        />
        <Input
          label="Телефон"
          name="phone"
          value={phone}
          onChange={handlePhoneChange}
          required
        />
      </div>
    </div>
  );
};

export default Step1;
