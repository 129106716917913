import { Outlet } from "react-router-dom";
import ClientLayout from "../../components/client_components/ClientLayout/ClientLayout";

const Dashboard = () => {
  return (
    <div className="h-full">
      <ClientLayout>
        <Outlet />
      </ClientLayout>
    </div>
  );
};

export default Dashboard;
