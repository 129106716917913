import Drawer from "../Drawer/Drawer";
import Footer from "../Footer/Footer";

import { useState } from "react";
import Toolbar from "../Toolbar/Toolbar";

const UserLayout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <>
      <Toolbar toggle={toggleDrawer} />
      <Drawer open={drawerOpen} toggle={toggleDrawer} />
      <main className="bg-white">{children}</main>
      <Footer />
    </>
  );
};

export default UserLayout;
