import React, { forwardRef } from "react";
import Barcode from "react-barcode";

import logo from "../../../assets/logos/logo.svg";
import robo from "../../../assets/robo/robo.png";

const BarcodeCard = forwardRef(
  ({ selectedCellCode, selectedCellData }, ref) => {
    return (
      <div
        className="bg-white w-[62.5mm] h-[39.6mm] px-[1mm] py-[1mm] relative border-black flex flex-col justify-center items-center"
        ref={ref}
      >
        <div>
          <p className="flex justify-center items-center mt-2">
            <img src={robo} alt="robo" className="w-[32px] mr-2" />{" "}
            <img src={logo} alt="SmartBox" />
          </p>
          <p className="text-[14px] text-center font-normal">
            Постамат #{selectedCellData.postId}
          </p>
          <p className="text-[14px] text-center font-bold">
            {selectedCellData.name}
          </p>
        </div>
        <div className="w-full flex justify-center items-center">
          <Barcode
            value={selectedCellCode}
            height={30}
            width={2}
            fontSize={12}
          />
        </div>
      </div>
    );
  }
);

export default BarcodeCard;
