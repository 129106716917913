import about_us from "../../assets/about-bg.webp";

const About = () => {
  return (
    <div className="flex flex-col sm:flex-row justify-evenly p-7 pt-20 sm:p-20 bg-blue-gray-200">
      <div className="w-full sm:w-2/3">
        <h2 className="text-4xl font-semibold mb-4">
          Добро пожаловать в SmartBox!
        </h2>
        <p className="text-gray-700">
          В SmartBox мы революционизируем то, как люди получают пакеты. Наш
          инновационный стартап предназначен для обеспечения бесперебойного
          решение для бизнеса и физических лиц. Представьте себе мир, где вы
          можете удобно забрать ваши онлайн покупки в месте рядом Вы, устраняя
          необходимость ждать дома для доставки или иметь дело с пропущенные
          пакеты.
        </p>
        <p className="mt-4 text-gray-700">
          Наши умные постаматы служат мостом между интернет-магазинами и
          клиенты. Предприятия могут теперь хранить свои продукты в нашей
          безопасной постаматы, и клиенты могут получить свои заказы по своему
          усмотрению. Нет больше ожидания, нет больше пропущенных поставок -
          SmartBox делает это легко.
        </p>
        <p className="mt-4 text-gray-700">
          Если вы бизнес ищет эффективный способ доставки товаров к вашим
          клиентам или индивидуалу устали ждать пакетов, мы здесь, чтобы сделать
          вашу жизнь легче. Присоединяйтесь к нам в нашем путешествии, чтобы
          пересмотреть доставка пакета!
        </p>
      </div>
      <div>
        <img src={about_us} alt="about us" className="w-full mt-5" />
      </div>
    </div>
  );
};

export default About;
