import { Button, Textarea } from "@material-tailwind/react";

const Step3 = ({ formData, setFormData, handleSubmit }) => {
  return (
    <div>
      <div className="text-xl font-semibold mb-4">
        Шаг 3: Дополнительная информация
      </div>
      <Textarea
        className="mt-4 block w-full p-2 border rounded-md"
        rows="5"
        placeholder="Доп. Информация"
        name="description"
        value={formData.description}
        onChange={(e) => {
          setFormData((prevData) => ({
            ...prevData,
            description: e.target.value,
          }));
        }}
      ></Textarea>

      <Button
        variant="filled"
        onClick={handleSubmit}
        className="bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 mt-4 text-sm"
      >
        Отправить
      </Button>
    </div>
  );
};

export default Step3;
