const TbodyBox = ({ children }) => {
  return (
    <td className="py-3 px-5 border-b">
      <p className="block antialiased text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
        {children}
      </p>
    </td>
  );
};

export default TbodyBox;
