import ApplicationTable from "../../components/admin_components/ApplicationTable/ApplicationTable";

const ApplicationInspect = () => {
  return (
    <div>
      <ApplicationTable />
    </div>
  );
};

export default ApplicationInspect;
