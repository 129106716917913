import classes from "./Nav.module.css";

import { MNavItem } from "./NavItem/NavItem";

import { motion } from "framer-motion";

const link_animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const Nav = ({ drawer }) => {
  const navHidden = `${drawer ? "flex" : "hidden"}`;

  return (
    <motion.ul
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={`${classes.Nav} flex-col justify-start items-start p-0 sm:my-0 my-4 list-none ${navHidden}`}
    >
      <MNavItem variants={link_animation} custom={2} url="/dashboard">
        Партнерам
      </MNavItem>
      <MNavItem variants={link_animation} custom={3} url="/about">
        О Нас
      </MNavItem>
      <MNavItem variants={link_animation} custom={4} url="/">
        Контакты
      </MNavItem>
    </motion.ul>
  );
};

export default Nav;
