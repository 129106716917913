import moment from "moment-timezone";

export function filterCellData(data, dateRange, postId, size) {
  let filteredData = [...data];

  // Фильтр по диапазону дат
  if (dateRange.startDate && dateRange.endDate) {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;

    filteredData = filteredData.filter((item) =>
      moment(item.time_reserve_start).isBetween(startDate, endDate, "day", "[]")
    );
  }

  // Фильтр по Box ID
  if (postId) {
    filteredData = filteredData.filter((item) => item.postId === postId);
  }

  // Фильтр по размеру
  if (size) {
    filteredData = filteredData.filter((item) => item.size === size);
  }

  return filteredData;
}
