import { useDispatch, useSelector } from "react-redux";
import { markNotificationAsRead } from "../../../../redux/reducers/notificationSlice";

const NotificationList = () => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  return (
    <div className="notification-list min-w-[180px] rounded-md overflow-auto border-0 p-[2px]">
      <ul className="font-sans text-sm font-normal w-full ">
        {notifications.length === 0 ? (
          <li>Нет уведомлений</li>
        ) : (
          <>
            {notifications.map((notification, index) => (
              <li
                key={index}
                className={`w-full pt-[9px] pb-2 px-3 rounded-md text-start leading-tight cursor-pointer select-none transition-all hover:bg-[#f3f3f3] gap-3 ${
                  notification.read ? "opacity-50" : ""
                }`}
                onClick={() => dispatch(markNotificationAsRead(index))}
              >
                <p>{notification.message}</p>
                <p>Reserved at:</p>
                <p>{notification.time}</p>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default NotificationList;
