import { formatDateTime } from "../../../../utils/tableUtils";
import { MdClose } from "react-icons/md";

const HistoryPopup = ({ isOpen, selectedCellHistory, closeHistoryModal }) => {
  const lastHistoryItem = selectedCellHistory[selectedCellHistory.length - 1];

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="history-section bg-gray-100 p-6 rounded-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[240px] sm:w-[325px]">
          <h2 className="text-xl font-bold mb-4">История ячейки</h2>

          <div className="mb-4">
            <p>
              <strong>BoxID:</strong> {lastHistoryItem.postId}
            </p>
            <p>
              <strong>Код Закладки:</strong> {lastHistoryItem.cellCodePut}
            </p>
            <p>
              <strong>Код Получения:</strong> {lastHistoryItem.cellCodeGet}
            </p>
            <p>
              <strong>Телефон:</strong> {lastHistoryItem.phone}
            </p>
            <p>
              <strong>Размер:</strong> {lastHistoryItem.size}
            </p>
            <p>
              <strong>Имя:</strong> {lastHistoryItem.name}
            </p>
            <p>
              <strong>Комментарий:</strong> {lastHistoryItem.comment}
            </p>
          </div>

          <ul className="list-none">
            {selectedCellHistory.map((historyItem, index) => (
              <li key={index} className="mb-4">
                <span
                  className={`${
                    historyItem.status === "Completed" ? "text-green-500" : ""
                  }${
                    historyItem.status === "Time expired" ? "text-red-500" : ""
                  }`}
                >
                  <p>
                    <strong>Статус:</strong> {historyItem.status}
                  </p>
                </span>

                {historyItem.status === "Reserve" && (
                  <div className="text-sm">
                    <p>
                      <strong>Начало:</strong>{" "}
                      {formatDateTime(historyItem.time_reserve_start)}
                    </p>
                    <p>
                      <strong>Конец:</strong>{" "}
                      {formatDateTime(historyItem.time_reserve_end)}
                    </p>
                  </div>
                )}

                {historyItem.status === "Busy" && (
                  <div className="text-sm">
                    <p>
                      <strong>Начало:</strong>{" "}
                      {formatDateTime(historyItem.get_time_start)}
                    </p>
                    <p>
                      <strong>Конец:</strong>{" "}
                      {formatDateTime(historyItem.get_time_end)}
                    </p>
                  </div>
                )}

                {historyItem.status === "Completed" && (
                  <span className="text-sm">
                    <strong>Время:</strong>{" "}
                    {formatDateTime(historyItem.request_time)}
                  </span>
                )}

                {historyItem.status === "Time expired" && (
                  <span className="text-sm">
                    <strong>Время:</strong>{" "}
                    {formatDateTime(historyItem.request_time)}
                  </span>
                )}
              </li>
            ))}
          </ul>

          <button
            className="middle none font-sans font-medium text-center uppercase transition-all w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xl hover:bg-black/10 absolute right-0 top-0 grid rounded-br-none rounded-tl-none"
            onClick={closeHistoryModal}
          >
            <span className="text-black absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
              <MdClose />
            </span>
          </button>
        </div>
      </div>
    )
  );
};

export default HistoryPopup;
