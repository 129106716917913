import { clearUser } from "../../../../redux/reducers/userSlice";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const AsideSignOut = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        dispatch(clearUser());
        navigate("/auth");
      })
      .catch((error) => {
        console.log("Error signing out:", error);
      });
  };

  return (
    <li className="w-full">
      <button
        className="w-full hover:bg-white/10 transition-all p-3 rounded-md antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize flex justify-start items-center"
        onClick={handleSignOut}
      >
        {children}
      </button>
    </li>
  );
};

export default AsideSignOut;
