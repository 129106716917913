import { RiMenu2Fill } from "react-icons/ri";

const NavToggle = ({ callBack }) => {
  return (
    <div
      onClick={callBack}
      className="font-sans relative cursor-pointer font-medium uppercase transition-all text-md py-3 rounded-lg w-10 max-w-[40px] h-10 max-h-[40px] hover:bg-slate-300 items-center gap-1 px-4 xl:hidden"
    >
      <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <RiMenu2Fill />
      </span>
    </div>
  );
};

export default NavToggle;
