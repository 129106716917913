import axios from "axios";
import { useState } from "react";

const CompanyDetails = ({ company, onClose }) => {
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");

  const handleSave = async () => {
    try {
      const data = {
        status: status,
        comm: comment,
        user_pool_id: "us-east-1_1Y9JreFD4",
        sub: company.sub,
      };
      const res = await axios.post(
        "https://m64b6opxp3.execute-api.us-east-1.amazonaws.com/test/",
        data
      );
      const messageData = res.data;
      console.log(messageData);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md relative">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        onClick={onClose}
      >
        Закрыть
      </button>
      <h2 className="text-lg font-semibold mb-4">{company.name}</h2>
      <p>{company.address}</p>
      <p>{company.desc}</p>
      <p>{company.phone}</p>
      <p>{company.file}</p>
      <img
        src={company.img}
        alt={`${company.name} Logo`}
        className="w-16 h-16 rounded-full mt-4"
      />

      <div className="mt-4">
        <label
          className="block text-gray-600 text-sm font-semibold"
          htmlFor="statusCompany"
        >
          Статус:
        </label>
        <select
          id="statusCompany"
          className="w-full p-2 border border-gray-300 rounded mt-2"
          value={status}
          name="statusCompany"
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="">Выбрать статус</option>
          <option value="approve">Approve</option>
          <option value="fail">Fail</option>
        </select>
      </div>

      <div className="mt-4">
        <label
          className="block text-gray-600 text-sm font-semibold"
          htmlFor="comment"
        >
          Заметки:
        </label>
        <textarea
          id="comment"
          rows="4"
          name="comment"
          className="w-full p-2 border border-gray-300 rounded mt-2"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <button
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
        onClick={handleSave}
      >
        Сохранить
      </button>
    </div>
  );
};

export default CompanyDetails;
