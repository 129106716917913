import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AWS from "aws-sdk";

const initialState = {
  postamatData: [], 
  loading: false,
  error: null,
};

export const fetchpostamatData = createAsyncThunk("postamat/fetchpostamatData", async (_, thunkAPI) => {
  try {
    AWS.config.update({
      region: "us-east-1",
      endpoint: "dynamodb.us-east-1.amazonaws.com",
      accessKeyId: "AKIARUKHG4KLLR7BOH43",
      secretAccessKey: "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",
    });

    const docClient = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: "postamat", 
    };

    const data = await docClient.scan(params).promise();
    return data.Items;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const postamatSlice = createSlice({
  name: "postamat",
  initialState,
  reducers: {
    // Дополнительные редюсеры, если они понадобятся
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchpostamatData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchpostamatData.fulfilled, (state, action) => {
        state.loading = false;
        state.postamatData = action.payload;
      })
      .addCase(fetchpostamatData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Экспортируем экшены из slice
// export const { /* Дополнительные экшены, если они понадобятся */ } = postamatSlice.actions;

export default postamatSlice.reducer;
