import { FaMoneyBillWave } from "react-icons/fa";
import { BiSolidInbox } from "react-icons/bi";
import { MdOutlineMonetizationOn } from "react-icons/md";
import StatisticCard from "./StatisticCard/StatisticCard";
import { useSelector } from "react-redux";

const StatisticCardGroup = ({ report }) => {
  const admin = useSelector((state) => state.admin);

  const smallData =
    report && report.small ? report.small : { count: 0, totalAmount: 0 };
  const mediumData =
    report && report.medium ? report.medium : { count: 0, totalAmount: 0 };
  const bigData =
    report && report.big ? report.big : { count: 0, totalAmount: 0 };

  const totalAmout =
    smallData.totalAmount + mediumData.totalAmount + bigData.totalAmount;

  return (
    <div className="my-4">
      <div className="grid gap-y-10 gap-x-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
        <StatisticCard
          icon={<FaMoneyBillWave className="w-[22px] h-[22px]" />}
          bgColor="blue"
          title="Balance"
          text={`$ ${admin.balance}`}
          percentage="+3% than last month"
        />
        <StatisticCard
          icon={<BiSolidInbox className="w-[19px] h-[19px]" />}
          bgColor="pink"
          title="Small"
          text={`Count: ${smallData.count}`}
          percentage="+3% than last month"
        />
        <StatisticCard
          icon={<BiSolidInbox className="w-[22px] h-[22px]" />}
          bgColor="green"
          title="Medium"
          text={`Count: ${mediumData.count}`}
          percentage="+3% than last month"
        />
        <StatisticCard
          icon={<BiSolidInbox className="w-[25px] h-[25px]" />}
          bgColor="orange"
          title="Big"
          text={`Count: ${bigData.count}`}
          percentage="+3% than last month"
        />
        <StatisticCard
          icon={<MdOutlineMonetizationOn className="w-[25px] h-[25px]" />}
          bgColor="gray"
          title="Total"
          text={`$ ${totalAmout}`}
          percentage="+3% than last month"
          className="xl:col-span-2"
        />
      </div>
    </div>
  );
};

export default StatisticCardGroup;
