import { useSelector } from "react-redux";

const UserCart = () => {
  const admin = useSelector((state) => state.admin);

  if (!admin) {
    return <div className="UserCart py-4 text-lg font-bold">admin</div>;
  }

  return (
    <div className="UserCart py-4 text-lg font-normal flex justify-start items-center flex-col">
      <div className="flex justify-center items-center">
        <p className="mr-3 font-bold">{admin.username}</p>
      </div>
    </div>
  );
};

export default UserCart;
