import React, { useState } from "react";
import { FaWallet } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import UserIdModal from "./UserIdModal/UserIdModal";

const UserCart = () => {
  const user = useSelector((state) => state.user);
  const [showQRCode, setShowQRCode] = useState(false); // Состояние для отображения QR-кода

  const handleQRCodeClick = () => {
    setShowQRCode(true); // При нажатии на userId, открываем QR-код
  };

  const handleCloseQRCode = () => {
    setShowQRCode(false); // При нажатии на кнопку "Закрыть", закрываем QR-код
  };

  if (!user) {
    return <div className="UserCart py-4 text-lg font-bold">User</div>;
  }

  return (
    <div className="UserCart pt-1 pb-4 text-lg font-normal flex justify-start items-start flex-col">
      <div className="flex justify-center items-center">
        <p className="mr-2 cursor-pointer" onClick={handleQRCodeClick}>
          {user.userId}
        </p>
        <p>/</p>
        <Link to="/dashboard/wallet">
          <p className="flex justify-start items-center ml-2">
            <FaWallet className="mr-2" /> {user.balance}
          </p>
        </Link>
      </div>
      <p className="font-bold">{user.name}</p>

      {showQRCode && (
        <UserIdModal userId={user.userId} onClose={handleCloseQRCode} />
      )}
    </div>
  );
}

export default UserCart;
