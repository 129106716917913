import SmartBoxTable from "../../components/admin_components/SmartBoxTable/SmartBoxTable";

const SmartBox = () => {
  return (
    <div>
      <SmartBoxTable />
    </div>
  );
};

export default SmartBox;
