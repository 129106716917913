import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import { setUser } from "../../../redux/reducers/userSlice";
import FullScreenLoad from "../../common_components/FullScreenLoad/FullScreenLoad";

const SignIn = ({ toggle }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = await Auth.signIn(email, password);
      dispatch(setUser(user));
      if (user && Number(user.attributes.family_name) <= 0) {
        navigate("/dashboard/wallet");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <FullScreenLoad />
      ) : (
        <form
          className="flex flex-col max-w-xs w-full bg-white rounded-lg shadow-lg mt-12 p-7 sm:p-8 md:p-10 lg:p-12 mx-4 font-sans font-light"
          onSubmit={handleLogin}
        >
          {error && (
            <div className="Error mb-6 text-red-700 uppercase font-semibold text-xs">
              {error}
            </div>
          )}
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="emailField"
            >
              Эл. почта
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="email"
              id="emailField"
              name="emailField"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          </div>
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="passwordField"
            >
              Пароль
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="password"
              id="passwordField"
              name="passwordField"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </div>

          <button
            className="h-12 px-6 w-full bg-blue-600 rounded font-semibold text-xs md:text-sm text-white hover:shadow-md shadow-gray-300 uppercase"
            type="submit"
          >
            Войти
          </button>
          <div className="flex mt-6 justify-center text-sm">
            <p className="text-xs sm:text-sm mr-1.5 font-light">
              Нет аккаунта?
            </p>
            <Link
              className="text-xs sm:text-sm text-blue-400 font-medium hover:text-blue-500"
              to="#"
              onClick={toggle}
            >
              Регистрация
            </Link>
          </div>
        </form>
      )}
    </>
  );
};

export default SignIn;
