import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAdmin } from "../../../redux/reducers/adminSlice";
import FullScreenLoad from "../../common_components/FullScreenLoad/FullScreenLoad";
import axios from "axios";

const AdminSignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = {
        username: formData.email,
        password: formData.password,
      };
      const requestData = {
        body: JSON.stringify(data),
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        "https://gs32ebwyqg.execute-api.us-east-1.amazonaws.com/test/",
        requestData,
        config
      );

      if (response.data.statusCode === 200) {
        const adminData = JSON.parse(response.data.body);
        dispatch(setAdmin(adminData));
        navigate("/adminpanel");
      } else {
        setError("Wrong login or password");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <FullScreenLoad />
      ) : (
        <form
          className="flex flex-col max-w-xs w-full bg-white rounded-lg shadow-lg mt-12 p-7 sm:p-8 md:p-10 lg:p-12 mx-4 font-sans font-light"
          onSubmit={handleLogin}
        >
          {error && (
            <div className="Error mb-6 text-red-700 uppercase font-semibold text-xs">
              {error}
            </div>
          )}
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              autoComplete="email"
            />
          </div>
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="password"
              id="password"
              name="password"
              required
              value={formData.password}
              onChange={handleChange}
              autoComplete="current-password"
            />
          </div>

          <button
            className="h-12 px-6 w-full bg-blue-600 rounded font-semibold text-xs md:text-sm text-white hover:shadow-md shadow-gray-300 uppercase"
            type="submit"
          >
            Sign in
          </button>
        </form>
      )}
    </>
  );
};

export default AdminSignIn;
