const StatisticCard = ({ icon, bgColor, text, title, percentage }) => {
  let colorClass = "";

  switch (bgColor) {
    case "blue":
      colorClass = "from-blue-600 to-blue-400 shadow-blue-500/40";
      break;
    case "pink":
      colorClass = "from-pink-600 to-pink-400 shadow-pink-500/40";
      break;
    case "green":
      colorClass = "from-green-600 to-green-400 shadow-green-500/40";
      break;
    case "orange":
      colorClass = "from-orange-600 to-orange-400 shadow-orange-500/40";
      break;
    case "gray":
      colorClass = "from-gray-600 to-gray-400 shadow-gray-500/40";
      break;
    default:
      break;
  }

  return (
    <div
      className={`relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md`}
    >
      <div
        className={`bg-gradient-to-tr ${colorClass} shadow-lg bg-clip-border mx-4 rounded-xl overflow-hidden text-whiteshadow-lg absolute -mt-4 grid h-16 w-16 place-items-center text-white`}
      >
        {icon}
      </div>
      <div className="p-4 text-right h-full">
        <p className="block antialiased font-sans text-sm leading-normal font-normal text-gray-600">
          {title}
        </p>
        <h4 className="block mt-3 antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-gray-900">
          {text}
        </h4>
      </div>
      <div className="border-t border-blue-gray-50 p-4">
        <p className="block antialiased font-sans text-base leading-relaxed font-normal text-gray-600">
          {percentage}
        </p>
      </div>
    </div>
  );
};

export default StatisticCard;
