import classes from "./Partners.module.css";

import aliexpress from "../../../../assets/partners/aliexpress_logo.svg";
import ozon from "../../../../assets/partners/ozon_logo.svg";
import iherb from "../../../../assets/partners/iherb_logo.svg";
import wildberries from "../../../../assets/partners/wildberries_logo.svg";
import pandao from "../../../../assets/partners/pandao_logo.svg";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const text_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const partners_animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const Partners = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      className={`${classes.Partners} pt-[520px] pb-[42px] sm:pb-[90px] md:pb-[156px] px-6 rounded-b-[40px] sm:rounded-b-[70px] md:rounded-b-[120px] lg:rounded-b-[140px] relative z-10 hd:max-w-[1700px] hd:mx-auto`}
    >
      <motion.h2
        variants={text_animation}
        custom={1}
        className={`${classes.partners_title} text-[#1C0448] text-[28px] sm:text-[32px] md:text-[36px] font-bold text-center max-w-[380px] sm:max-w-[480px] mx-auto`}
      >
        Наши партнеры вовсю пользуются SmartBox'ами
      </motion.h2>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.3, once: true }}
        className="flex justify-around items-center flex-wrap max-w-[500px] md:max-w-[1000px] mx-auto my-[55px] sm:my-[100px] md:my-[150px]"
      >
        <motion.img
          variants={partners_animation}
          custom={1}
          src={aliexpress}
          alt="aliexpress"
          className="max-h-[30px] mx-2 mt-2"
        />
        <motion.img
          variants={partners_animation}
          custom={5}
          src={ozon}
          alt="ozon"
          className="order-4 md:order-1 max-h-[20px] mx-2 mt-6"
        />
        <motion.img
          variants={partners_animation}
          custom={2}
          src={iherb}
          alt="iherb"
          className="max-h-[80px] mx-2 mt-2"
        />
        <motion.img
          variants={partners_animation}
          custom={3}
          src={wildberries}
          alt="wildberries"
          className="max-h-[50px] mx-2 mt-2"
        />
        <motion.img
          variants={partners_animation}
          custom={4}
          src={pandao}
          alt="pandao"
          className="max-h-[50px] mx-2 mt-2"
        />
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.3, once: true }}
        className="text-center"
      >
        <motion.div
          variants={text_animation}
          custom={1}
          className={`${classes.partners_text} max-w-[250px] sm:max-w-[550px] mx-auto`}
        >
          <p className="text-base text-[#1C0448] md:text-[24px]">
            Присоединяйтесь и станьте нашим партнером!
          </p>
        </motion.div>
        <motion.div
          variants={text_animation}
          custom={2}
          className="mt-9 sm:mt-16"
        >
          <Link
            to="/dashboard"
            className={`${classes.partners_btn} font-bold text-[32px] sm:text-[38px] md:text-[51px] px-[30px] sm:px-[52px] md:px-[66px] pt-[25px] sm:pt-[30px] md:pt-[34px] pb-[26px] sm:pb-[32px] md:pb-[36px] bg-[#0D79F2] text-white rounded-full max-w-[380px] sm:max-w-[450px] md:max-w-[600px] mx-auto block`}
          >
            Стать партнером!
          </Link>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Partners;
