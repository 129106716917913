import { FaWallet } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { BsClockHistory, BsTable, BsFillPenFill } from "react-icons/bs";
import { MdAllInbox } from "react-icons/md";
import { IoExitOutline } from "react-icons/io5";
import AsideNavItem from "./AsideNavItem/AsideNavItem";
import AsideSignOut from "./AsideSignOut/AsideSignOut";

const AsideNavbar = () => {
  const path = "adminpanel";

  return (
    <div className="m-4">
      <ul className="mb-4 flex flex-col gap-1">
        <AsideNavItem url={`/${path}`}>
          <AiFillHome className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">Главная</p>
        </AsideNavItem>
        <AsideNavItem url={`/${path}/table`}>
          <BsTable className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">Таблица</p>
        </AsideNavItem>
        <AsideNavItem url={`/${path}/history`}>
          <BsClockHistory className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">История</p>
        </AsideNavItem>
        <AsideNavItem url={`/${path}/wallet`}>
          <FaWallet className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">Баланс</p>
        </AsideNavItem>
        <AsideNavItem url={`/${path}/smartbox`}>
          <MdAllInbox className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">SmartBox</p>
        </AsideNavItem>
        <AsideNavItem url={`/${path}/smartboxsizes`}>
          <MdAllInbox className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">SmartBox size</p>
        </AsideNavItem>
        <AsideNavItem url={`/${path}/application-inspect`}>
          <BsFillPenFill className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">Заявки</p>
        </AsideNavItem>
      </ul>
      <ul className="mb-4 flex flex-col gap-1 md:hidden">
        <li className="w-full mx-3.5 mt-4 mb-2">
          <p className="block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">
            Auth Pages
          </p>
        </li>
        <AsideSignOut>
          <IoExitOutline className="mr-3 w-[20px] h-[20px]" /> Выйти
        </AsideSignOut>
      </ul>
    </div>
  );
};

export default AsideNavbar;
