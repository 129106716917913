import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminPrivateRoute = ({ element }) => {
  const admin = useSelector((state) => state.admin);

  if (!admin) {
    return <Navigate to="/admin" replace />;
  }

  return <>{element}</>;
};

export default AdminPrivateRoute;
