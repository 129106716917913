import React, { useEffect, useState } from "react";
import Notification from "../../components/admin_components/Notification/Notification";
import CellForm from "../../components/admin_components/CellForm/CellForm";
import CellTable from "../../components/admin_components/CellTable/CellTable";

const AdminTable = () => {
  const [showCellForm, setShowCellForm] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [notification, setNotification] = useState(null);

  const handleNotification = (message, type = "") => {
    setNotification({ message, type });
  };

  const handleCellFormToggle = () => {
    setShowCellForm(!showCellForm);
  };

  const handleTableRefresh = () => {
    setUpdateCounter(updateCounter + 1);
  };

  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  return (
    <div className="Home">
      {showCellForm ? (
        <CellForm
          onClose={handleCellFormToggle}
          onCellCodePut={handleTableRefresh}
          onNotification={handleNotification}
        />
      ) : (
        <div className="text-center my-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={handleCellFormToggle}
          >
            Cell Request
          </button>
        </div>
      )}
      {notification && (
        <Notification message={notification.message} type={notification.type} />
      )}
      <CellTable updateCounter={updateCounter} />
    </div>
  );
};

export default AdminTable;
