import BalanceTable from "../../components/admin_components/BalanceTable/BalanceTable";

const AdminWallet = () => {
  return (
    <div>
      <BalanceTable />
    </div>
  );
};

export default AdminWallet;
