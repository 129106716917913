import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import ClientHome from "../../../pages/client_pages/ClientHome";

const PrivateRoute = ({ element }) => {
  const user = useSelector((state) => state.user);

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  // Проверяем статус пользователя
  if (user && user.status !== "approve") {
    // Если element является компонентом Home, просто возвращаем его
    if (React.isValidElement(element) && element.type === ClientHome) {
      return element;
    } else {
      // Иначе перенаправляем на главную страницу
      return <Navigate to="/" replace />;
    }
  }

  return <>{element}</>;
};

export default PrivateRoute;
