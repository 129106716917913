import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

const userSlice = createSlice({
  name: "user",
  initialState: null,
  reducers: {
    setUser: (state, action) => {
      return action.payload;
    },
    clearUser: (state) => {
      return null;
    },
  },
});

export const setUser = () => {
  return (dispatch) => {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        const { username, attributes, signInUserSession } = currentUser;
        const userData = {
          username,
          userId: attributes.preferred_username,
          email: attributes.email,
          name: attributes.name,
          status: attributes.profile,
          balance: Number(attributes.family_name),
          token: signInUserSession.idToken.jwtToken,
        };
        dispatch({ type: "user/setUser", payload: userData });
      })
      .catch((error) => {
        dispatch(clearUser());
        console.log("Error fetching user:", error);
      });
  };
};

export const clearUser = () => {
  return { type: "user/clearUser" };
};

export default userSlice.reducer;

// const initialState = {
//   user: null,
// };

// const userSlice = createSlice({
//   name: "user",
//   initialState,
//   reducers: {
//     setUser: (state, action) => {
//       return {
//         ...state,
//         user: action.payload,
//       };
//     },
//     clearUser: (state) => {
//       return {
//         ...state,
//         user: null,
//       };
//     },
//   },
// });