const Notification = ({ message, type }) => {
  let textColorClass = "text-red-500";

  if (type === "success") {
    textColorClass = "text-green-500";
  }

  return (
    <div
      className={`Notification z-50 bg-white px-4 py-2 absolute top-4 left-1/2 transform -translate-x-1/2 border rounded-md ${textColorClass}`}
    >
      {message}
    </div>
  );
};

export default Notification;
