import { useRef } from "react";
import AboutSmartBox from "../../components/user_components/AboutSmartBox/AboutSmartBox";
import Header from "../../components/user_components/Header/Header";
import SmartBoxInfo from "../../components/user_components/SmartBoxInfo/SmartBoxInfo";
import SmartInstructions from "../../components/user_components/SmartInstructions/SmartInstructions";
import SmartSwiper from "../../components/user_components/SmartSwiper/SmartSwiper";

const UserHome = () => {
  const aboutSmartBoxRef = useRef(null);
  const smartInstructionsRef = useRef(null);

  const scrollToAboutSmartBox = () => {
    aboutSmartBoxRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const scrollToSmartInstructions = () => {
    smartInstructionsRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header scrollToNextSection={scrollToAboutSmartBox} />
      <AboutSmartBox
        sectionRef={aboutSmartBoxRef}
        scrollToNextSection={scrollToSmartInstructions}
      />
      <SmartInstructions sectionRef={smartInstructionsRef} />
      <SmartSwiper />
      <SmartBoxInfo />
    </>
  );
};

export default UserHome;
