const TbodyBox = ({ children, open }) => {
  return (
    <td className="py-3 px-3 border-b cursor-pointer" onClick={open}>
      <p className="block antialiased text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
        {children}
      </p>
    </td>
  );
};

export default TbodyBox;
