import { Link } from "react-router-dom";

import rodo from "../../../../assets/robo/sad.svg";
import triangle from "../../../../assets/vectors/triangle-orange.svg";

const PickupExpired = () => {
  return (
    <div className="pt-[85px] font-roboto w-full">
      <div className="mb-[10px]">
        <img src={rodo} alt="rodo" className="mb-4" />

        <div className="bg-[#FB5012] text-white inline-block py-4 px-5 rounded-[20px] text-xl relative">
          <img
            src={triangle}
            alt="triangle"
            className="absolute top-[-16px] left-0"
          />
          <p className="leading-6">
            Вы не забрали заказ <br /> вовремя 😢
          </p>
        </div>
      </div>
      <div className="bg-[#EFF2F5] rounded-[20px] inline-block py-4 px-5">
        <p className="text-xl leading-6">
          Пожалуйста, <br /> обратитесь по номеру:
        </p>
        <Link
          to="tel:0703434242"
          className="bg-[#0D79F2] text-white text-base inline-block text-center rounded-full px-4 pt-[11px] pb-[10px] mt-3"
        >
          <p>0 703 43 42 42</p>
        </Link>
      </div>
    </div>
  );
};

export default PickupExpired;
