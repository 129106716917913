import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCellData } from "../../../redux/reducers/transactionSlice";

import BalanceTbody from "./BalanceTbody/BalanceTbody";
import BalanceThead from "./BalanceThead/BalanceThead";
import "./BalanceTable.css";

const BalanceTable = () => {
  const dispatch = useDispatch();
  const { balanceData } = useSelector((state) => state.transaction);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCellData(user, { dispatch }));
  }, [dispatch, user]);

  return (
    <div className="CellTable w-full rounded-lg shadow-sm overflow-hidden bg-white">
      <div className="balance_table_container w-full overflow-auto max-h-[390px]">
        <table className="w-full table-auto">
          <thead>
            <tr>
              <BalanceThead>Количество</BalanceThead>
              <BalanceThead>Дата</BalanceThead>
            </tr>
          </thead>
          <tbody>
            {balanceData.map((item, index) => (
              <tr key={index}>
                <BalanceTbody>{item.amount}</BalanceTbody>
                <BalanceTbody>{item.date}</BalanceTbody>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BalanceTable;
