import BalanceTable from "../../components/client_components/BalanceTable/BalanceTable";

const ClientWallet = () => {
  return (
    <div>
      <BalanceTable />
    </div>
  );
};

export default ClientWallet;
