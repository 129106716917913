import React, { useState } from "react";
import { Stepper, Step, Button } from "@material-tailwind/react";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import axios from "axios";
import { useSelector } from "react-redux";
import Loading from "../../common_components/Loading/Loading";

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    address: "",
    phone: "",
    email: "",
    description: "",
    companyLogo: null,
    companyLogoUrl: null,
    document: null,
    documentUrl: null,
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const logoFile = formData.companyLogo;
      const documentFile = formData.document;

      const readerLogo = new FileReader();
      const readerDocument = new FileReader();

      readerLogo.onload = async (e) => {
        const logoBase64Content = e.target.result.split(",")[1];

        readerDocument.onload = async (e) => {
          const documentBase64Content = e.target.result.split(",")[1];

          const data = {
            company_name: formData.company_name,
            sub: user.username,
            name: formData.name,
            address: formData.address,
            phone: formData.phone,
            email: "test@gmail.com",
            desc: formData.description,
            img: logoBase64Content,
            img_key: logoFile.name,
            file: documentBase64Content,
            file_key: documentFile.name,
          };

          const config = {
            headers: {
              Authorization: user.token,
            },
          };

          // Перед отправкой устанавливаем состояние загрузки
          setIsSubmitting(true);

          const response = await axios.post(
            "https://va2e6rolxe.execute-api.us-east-1.amazonaws.com/work/",
            data,
            config
          );

          if (response.data.errorMessage) {
            setIsSubmitting(false);
            setError("Произошла ошибка, пожалуйста, попробуйте позже.");
          } else {
            setIsSubmitting(false);
            setSubmissionComplete(true);
          }
        };

        readerDocument.readAsDataURL(documentFile);
      };

      readerLogo.readAsDataURL(logoFile);
    } catch (error) {
      console.error(error);
      setError("Произошла ошибка, пожалуйста, попробуйте позже.");
    }
  };

  const steps = ["Step 1", "Step 2", "Step 3"];

  return (
    <div className="p-2 w-full bg-white rounded-lg">
      {isSubmitting ? (
        <Loading />
      ) : submissionComplete ? (
        <p className="text-green-600 font-semibold text-center">
          Ваш запрос принят, ожидайте подтверждения.
        </p>
      ) : error ? (
        <p className="text-red-600 font-semibold text-center">{error}</p>
      ) : (
        <>
          <div className="text-3xl font-semibold text-center mb-4">
            Форма Заявки
          </div>
          <Stepper activeStep={activeStep}>
            <Step
              onClick={() => setActiveStep(0)}
              className="cursor-pointer bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 text-white/75"
              activeClassName="text-white"
            >
              1
            </Step>
            <Step
              onClick={() => setActiveStep(1)}
              className="cursor-pointer bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 text-white/75"
              activeClassName="text-white"
            >
              2
            </Step>
            <Step
              onClick={() => setActiveStep(2)}
              className="cursor-pointer bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 text-white/75"
              activeClassName="text-white"
            >
              3
            </Step>
          </Stepper>
          <div className="mt-4">
            {activeStep === 0 && (
              <Step1 formData={formData} setFormData={setFormData} />
            )}
            {activeStep === 1 && (
              <Step2 formData={formData} setFormData={setFormData} />
            )}
            {activeStep === 2 && (
              <Step3
                formData={formData}
                setFormData={setFormData}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
          <div className="mt-4">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="filled"
              className="mr-5 bg-gradient-to-br from-blue-gray-800 to-blue-gray-900"
            >
              Назад
            </Button>
            {activeStep < steps.length - 1 && (
              <Button
                variant="filled"
                onClick={handleNext}
                className="bg-gradient-to-br from-blue-gray-800 to-blue-gray-900"
              >
                Вперед
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicationForm;
