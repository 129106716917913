import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import { fetchcompaniesData } from "../../../redux/reducers/companiesSlice";

const ApplicationTable = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [displayDetails, setDisplayDetails] = useState(false);

  const dispatch = useDispatch();
  const { companiesData, loading, error } = useSelector(
    (state) => state.companies
  );

  console.log(companiesData);

  useEffect(() => {
    dispatch(fetchcompaniesData());
  }, [dispatch]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  const handleMoreInfo = (company) => {
    setSelectedCompany(company);
    setDisplayDetails(true);
  };

  const closeMoreInfo = () => {
    setSelectedCompany(null);
    setDisplayDetails(false);
  };

  return (
    <div>
      {displayDetails ? (
        <CompanyDetails company={selectedCompany} onClose={closeMoreInfo} />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {companiesData.map((company) => (
            <div
              key={company.companyId} // Здесь используйте уникальный идентификатор компании
              className="bg-white rounded-lg shadow-md p-4 cursor-pointer hover:shadow-lg transition duration-300"
            >
              <img
                src={company.img}
                alt={`${company.company_name} Logo`}
                className="w-16 h-16 rounded-full mx-auto mb-4"
              />
              <h2 className="text-lg font-semibold text-center">
                {company.company_name}
              </h2>
              <p className="text-sm text-gray-600 text-center">
                {company.address}
              </p>
              <button
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                onClick={() => handleMoreInfo(company)}
              >
                Инфо
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ApplicationTable;
