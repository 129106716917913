import React, { useEffect, useMemo, useState } from "react";
import TheadBox from "./TheadBox/TheadBox";
import TbodyBox from "./TbodyBox/TbodyBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchCellData } from "../../../redux/reducers/transactionSlice";
import moment from "moment-timezone";
import "./ReportTable.css";
import DateRangePicker from "../DateRangePicker/DateRangePicker";

const ReportTable = ({ onReport }) => {
  const dispatch = useDispatch();
  const { completedCellData } = useSelector((state) => state.transaction);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().startOf("isoWeek").toDate(),
    endDate: moment().endOf("isoWeek").toDate(),
  });
  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCellData(user, { dispatch }));
  }, [dispatch, user]);

  const handleValueChange = (dates) => {
    setSelectedDateRange({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  let sortedcompletedCellData = [...completedCellData];

  sortedcompletedCellData.sort((a, b) =>
    b.time_reserve_start.localeCompare(a.time_reserve_start)
  );

  if (selectedDateRange.startDate && selectedDateRange.endDate) {
    const startDate = selectedDateRange.startDate;
    const endDate = selectedDateRange.endDate;

    sortedcompletedCellData = sortedcompletedCellData.filter((item) =>
      moment(item.time_reserve_start).isBetween(startDate, endDate, "day", "[]")
    );
  }

  const pricePerSize = useMemo(() => {
    return {
      small: 5,
      medium: 10,
      big: 15,
    };
  }, []);

  // Создаем объект для хранения количества и суммы для каждого размера
  const sizeData = {
    small: { count: 0, totalAmount: 0 },
    medium: { count: 0, totalAmount: 0 },
    big: { count: 0, totalAmount: 0 },
  };

  // Рассчитываем количество и сумму для каждого размера
  sortedcompletedCellData.forEach((item) => {
    const { size } = item;
    const price = pricePerSize[size] || 0;
    sizeData[size].count += 1;
    sizeData[size].totalAmount += price;
  });

  useEffect(() => {
    let filteredData = completedCellData;

    if (selectedDateRange.startDate && selectedDateRange.endDate) {
      filteredData = completedCellData.filter((item) =>
        moment(item.time_reserve_start).isBetween(
          selectedDateRange.startDate,
          selectedDateRange.endDate,
          "day",
          "[]"
        )
      );
    }

    const newSizeData = {
      small: { count: 0, totalAmount: 0 },
      medium: { count: 0, totalAmount: 0 },
      big: { count: 0, totalAmount: 0 },
    };

    filteredData.forEach((item) => {
      const { size } = item;
      const price = pricePerSize[size] || 0;
      newSizeData[size].count += 1;
      newSizeData[size].totalAmount += price;
    });

    const newTotalAmount = Object.values(newSizeData).reduce(
      (total, size) => total + size.totalAmount,
      0
    );

    onReport(newSizeData, newTotalAmount);
  }, [selectedDateRange, completedCellData, pricePerSize, onReport]);

  return (
    <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3">
      <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md py-3 px-5">
        <label className="flex flex-col md:w-1/2 xl:w-full" htmlFor="dateRange">
          <span className="text-sm font-medium text-gray-700 mb-1">
            Временной промежуток:
          </span>
          <DateRangePicker
            id="dateRange"
            name="dateRange"
            selectedDateRange={selectedDateRange}
            handleValueChange={handleValueChange}
          />
        </label>
      </div>
      <div className="w-full relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md overflow-hidden xl:col-span-2">
        <div className="overflow-hidden">
          <div className="report_table_container w-full overflow-auto max-h-[390px]">
            <table className="w-full table-auto">
              <thead>
                <tr>
                  <TheadBox>SmartBox</TheadBox>
                  <TheadBox>Размер</TheadBox>
                  <TheadBox>Статус</TheadBox>
                  <TheadBox>Дата Выполнения</TheadBox>
                </tr>
              </thead>
              <tbody>
                {sortedcompletedCellData.map((item, index) => (
                  <tr key={index}>
                    <TbodyBox>{item.postId}</TbodyBox>
                    <TbodyBox>{item.size}</TbodyBox>
                    <TbodyBox>{item.status}</TbodyBox>
                    <TbodyBox>{item.dateCompleted}</TbodyBox>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTable;
