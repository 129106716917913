import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  companiesData: [],
  loading: false,
  error: null,
};

export const fetchcompaniesData = createAsyncThunk("companies/fetchcompaniesData", async (_, thunkAPI) => {
  try {
    const data = {
      message: "Hello!",
    };
    const response = await axios
      .post(
        "https://hifdo8sxz2.execute-api.us-east-1.amazonaws.com/test",
        data
      )
      .catch((error) => {
        console.log(error.message);
      });
    
    const jsonResponse = response.data.body;
    const dataArray = JSON.parse(jsonResponse);
    return dataArray;
  } catch (error) {
    console.log(error.message);
  }
});

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    // Дополнительные редюсеры, если они понадобятся
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchcompaniesData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchcompaniesData.fulfilled, (state, action) => {
        state.loading = false;
        state.companiesData = action.payload.infoCompany;
      })
      .addCase(fetchcompaniesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },  
});

export default companiesSlice.reducer;
