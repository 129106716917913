import { Button } from "@material-tailwind/react";
import { Link, useMatch } from "react-router-dom";

const AsideNavItem = ({ url, children, toggle }) => {
  const isActive = useMatch(url);

  return (
    <li className="w-full">
      <Link to={url}>
        <Button
          className={`w-full transition-all rounded-md antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize flex justify-start items-center ${
            isActive
              ? "bg-gradient-to-tr from-blue-600 to-blue-400 hover:shadow-lg hover:shadow-blue-500/50 hover:bg-blue-400"
              : "hover:bg-white/10"
          }`}
          variant="text"
          onClick={toggle}
        >
          {children}
        </Button>
      </Link>
    </li>
  );
};

export default AsideNavItem;
