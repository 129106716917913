import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    addNotification: (state, action) => {
      const { message, time } = action.payload;
      state.push({
        message,
        time,
        read: false,
      });
    },
    
    markNotificationAsRead: (state, action) => {
      const notification = state[action.payload];
      if (notification) {
        notification.read = true;
      }
    },
  },  
});

export const { addNotification, markNotificationAsRead } = notificationSlice.actions;

export default notificationSlice.reducer;
