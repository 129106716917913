import HistoryTable from "../../components/client_components/HistoryTable/HistoryTable";

const ClientHistory = () => {
  return (
    <div>
      <HistoryTable />
    </div>
  );
};

export default ClientHistory;
