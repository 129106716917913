import classes from "./Header.module.css";

import logo from "../../../assets/logos/logo-2.svg";

import arrow from "../../../assets/vectors/arrow-down.svg";

import headerImage from "../../../assets/images/header.webp";
import haaderAdaptive from "../../../assets/images/header-adaptability.webp";
import haaderHd from "../../../assets/images/header-hd.webp";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const text_animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const image_animation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const arrow_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const Header = ({ scrollToNextSection }) => {
  return (
    <motion.header
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      className="font-manrope pb-[80px] bg-white overflow-hidden"
    >
      <div className="hd:max-w-[1700px] hd:mx-auto">
        <div className="flex items-center md:items-end justify-end flex-col-reverse md:flex-row">
          <div className="text-center">
            <div
              className={`${classes.header_text_block} pl-[30px] md:pl-[40px] mr-[30px] md:mr-0 text-center md:text-left`}
            >
              <motion.h2
                variants={text_animation}
                custom={1}
                className="text-[#1C0448] text-[42px] font-semibold leading-[50px] mb-[25px] max-w-[355px] md:max-w-[465px]"
              >
                Заказывай онлайн, получай в терминалах
              </motion.h2>
              <motion.img
                variants={text_animation}
                custom={2}
                src={logo}
                alt="SmartBox"
                className="max-w-[280px] w-full mx-auto md:m-0"
              />
            </div>
            <motion.div
              variants={arrow_animation}
              custom={1}
              onClick={scrollToNextSection}
              className={`${classes.arrow_down} inline-block mt-[30%]`}
            >
              <Link to="#">
                <img src={arrow} alt="arrow" />
              </Link>
            </motion.div>
          </div>
          <motion.img
            variants={image_animation}
            custom={1}
            src={haaderAdaptive}
            alt="header"
            className="w-full block md:hidden"
          />
          <motion.img
            variants={image_animation}
            custom={1}
            src={headerImage}
            alt="header"
            className="max-w-[60%] hidden md:block hd:hidden"
          />
          <motion.img
            variants={image_animation}
            custom={1}
            src={haaderHd}
            alt="header"
            className="max-w-[65%] hidden hd:block"
          />
        </div>
      </div>
    </motion.header>
  );
};

export default Header;
