import { Link } from "react-router-dom";

const FooterItem = ({ url, children }) => {
  return (
    <li>
      <Link
        to={url}
        className="block antialiased font-sans text-sm leading-normal py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
      >
        {children}
      </Link>
    </li>
  );
};

export default FooterItem;
