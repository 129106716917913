import React, { useEffect, useState } from "react";
import TheadBox from "./TheadBox/TheadBox";
import TbodyBox from "./TbodyBox/TbodyBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchCellData } from "../../../redux/reducers/transactionSlice";
import moment from "moment-timezone";
import "./HistoryTable.css";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { filterCellData } from "../../../utils/filterUtils";

const HistoryTable = () => {
  const dispatch = useDispatch();
  const { cellData } = useSelector((state) => state.transaction);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().startOf("isoWeek").toDate(),
    endDate: moment().endOf("isoWeek").toDate(),
  });

  const [selectedPostId, setSelectedPostId] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchCellData(user, { dispatch }));
  }, [dispatch, user]);

  const handleValueChange = (dates) => {
    setSelectedDateRange({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  const filteredData = filterCellData(
    cellData,
    selectedDateRange,
    selectedPostId,
    selectedSize
  );

  return (
    <>
      <div className="filters bg-white p-4 rounded-lg mb-3 mt-4">
        <div className="flex flex-col md:flex-row gap-4">
          <label className="flex flex-col md:w-1/3" htmlFor="dateRange">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Временной Промежуток:
            </span>
            <DateRangePicker
              selectedDateRange={selectedDateRange}
              handleValueChange={handleValueChange}
            />
          </label>
          <label className="flex flex-col md:w-1/3" htmlFor="boxId">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Box ID:
            </span>
            <select
              value={selectedPostId}
              onChange={(e) => setSelectedPostId(e.target.value)}
              className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-200 text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 h-full"
              id="boxId"
              name="boxId"
            >
              <option value="" className="bg-blue-gray-900">
                Все
              </option>
              <option value="1" className="bg-blue-gray-900">
                1
              </option>
              <option value="2" className="bg-blue-gray-900">
                2
              </option>
              <option value="3" className="bg-blue-gray-900">
                3
              </option>
            </select>
          </label>
          <label className="flex flex-col md:w-1/3" htmlFor="size">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Размер:
            </span>
            <select
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
              className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-200 text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 h-full"
              id="size"
              name="size"
            >
              <option value="" className="bg-blue-gray-900">
                Все
              </option>
              <option value="small" className="bg-blue-gray-900">
                Маленький
              </option>
              <option value="medium" className="bg-blue-gray-900">
                Средний
              </option>
              <option value="big" className="bg-blue-gray-900">
                Большой
              </option>
            </select>
          </label>
        </div>
      </div>

      <div className="w-full rounded-lg shadow-sm overflow-hidden bg-white">
        <div className="completed_table_container w-full overflow-auto max-h-[390px]">
          <table className="w-full table-auto">
            <thead>
              <tr>
                <TheadBox>ID</TheadBox>
                <TheadBox>Код Закладки</TheadBox>
                <TheadBox>Комментарий</TheadBox>
                <TheadBox>Статус</TheadBox>
                <TheadBox>Телефон</TheadBox>
                <TheadBox>Размер</TheadBox>
                <TheadBox>Начало Резерва</TheadBox>
                <TheadBox>Конец Резерва</TheadBox>
                <TheadBox>Время</TheadBox>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <TbodyBox>{item.postId}</TbodyBox>
                  <TbodyBox>{item.cellCodePut}</TbodyBox>
                  <TbodyBox>{item.comment}</TbodyBox>
                  <TbodyBox>{item.status}</TbodyBox>
                  <TbodyBox>{item.phone}</TbodyBox>
                  <TbodyBox>{item.size}</TbodyBox>
                  <TbodyBox>{item.time_reserve_start}</TbodyBox>
                  <TbodyBox>{item.time_reserve_end}</TbodyBox>
                  <TbodyBox>{item.request_time}</TbodyBox>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default HistoryTable;
