import logo from "../../../assets/logos/logo-2.svg";

import arrow from "../../../assets/vectors/arrow-down.svg";

import about from "../../../assets/images/about.webp";
import aboutAdaptive from "../../../assets/images/about-adaptability.webp";
import aboutHd from "../../../assets/images/about-hd.webp";

import classes from "./AboutSmartBox.module.css";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const text_animation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const image_animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const arrow_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const AboutSmartBox = ({ sectionRef, scrollToNextSection }) => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      ref={sectionRef}
      className="bg-white font-manrope flex items-center flex-col md:flex-row pb-[100px] md:pb-0 hd:max-w-[1700px] hd:mx-auto overflow-hidden"
    >
      <motion.img
        variants={image_animation}
        custom={1}
        src={aboutAdaptive}
        alt="About SmartBox"
        className="w-full block md:hidden"
      />
      <motion.img
        variants={image_animation}
        custom={1}
        src={about}
        alt="About SmartBox"
        className="max-w-[50%] w-full mr-[20px] hidden md:block hd:hidden"
      />
      <motion.img
        variants={image_animation}
        custom={1}
        src={aboutHd}
        alt="About SmartBox"
        className="max-w-[60%] w-full mr-[20px] hidden hd:block"
      />
      <div className="max-w-[500px] mx-auto md:mx-0 px-[40px] md:px-0">
        <motion.img
          variants={text_animation}
          custom={1}
          src={logo}
          alt="SmartBox"
          className={`${classes.logo} w-[175px] sm:w-[220px]`}
        />
        <div
          className={`${classes.text_block} text-[#1C0448] text-[18px] sm:text-[20px] lg:text-[24px] leading-[32px]`}
        >
          <motion.p
            variants={text_animation}
            custom={2}
            className={`${classes.first_paragraph} my-[20px] md:my-[30px] lg:my-[40px] max-w-[480px]`}
          >
            это современные терминалы по выдаче посылок, расположенные в разных
            точках города.
          </motion.p>
          <motion.p variants={text_animation} custom={3}>
            Теперь, ты можешь получать свои посылки из интернета в ближайшем
            терминале!
          </motion.p>
        </div>
        <motion.div
          variants={arrow_animation}
          custom={3}
          onClick={scrollToNextSection}
          className="hidden md:inline-block mt-[25%] lg:mt-[30%] hd:mt-[25%] ml-[32%]"
        >
          <Link to="#">
            <img src={arrow} alt="arrow down" />
          </Link>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default AboutSmartBox;
