import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../redux/reducers/notificationSlice";
import { clearUser } from "../../../redux/reducers/userSlice";
import Loading from "../../common_components/Loading/Loading";

const CellForm = ({ onClose, onCellCodePut, onNotification }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const form = e.target;
    const data = {
      client_unique_id: user.username,
      clientId: user.userId,
      postId: form.postId.value,
      size: form.size.value,
      phone: form.phone.value,
      comment: form.comment.value,
      name: form.name.value,
    };

    const config = {
      headers: {
        Authorization: user.token,
      },
    };

    try {
      const res = await axios
        .post(
          "https://1y35s884t2.execute-api.us-east-1.amazonaws.com/work",
          data,
          config
        )
        .catch((error) => {
          dispatch(clearUser());
          console.log("Error fetching user:", error);
        });
      const messageData = res.data;
      console.log(messageData);

      if (messageData.body === "All cells with required size are busy!") {
        onNotification(messageData.body);
      } else if (messageData.body.includes("cellCodeGet")) {
        onNotification("Cell reserved!", "success");
        const currentTime = new Date();
        const year = currentTime.getFullYear();
        const month = String(currentTime.getMonth() + 1).padStart(2, "0");
        const day = String(currentTime.getDate()).padStart(2, "0");
        const hours = String(currentTime.getHours()).padStart(2, "0");
        const minutes = String(currentTime.getMinutes()).padStart(2, "0");
        const seconds = String(currentTime.getSeconds()).padStart(2, "0");

        const reservationTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        dispatch(
          addNotification({
            message: "Cell reserved!",
            time: reservationTime,
          })
        );
        onCellCodePut();
        onClose();
      } else {
        onNotification("An error occurred. Please try again later.");
      }
    } catch (err) {
      console.error(err);
      onNotification("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <form
      className="flex flex-col bg-white rounded-lg shadow-sm w-full p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 my-4"
      onSubmit={handleSubmit}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex flex-col items-center md:w-1/2">
              <div className="w-full md:px-5">
                <label className="font-semibold text-xs" htmlFor="postId">
                  SmartBox
                </label>
                <select
                  className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
                  name="postId"
                  required
                >
                  <option value="1">SmartBox №1</option>
                  <option value="2">SmartBox №2</option>
                  <option value="3">SmartBox №3</option>
                </select>
              </div>
              <div className="w-full md:px-5">
                <label className="font-semibold text-xs mt-3" htmlFor="size">
                  Размер
                </label>
                <select
                  className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
                  name="size"
                  required
                >
                  <option value="small">Маленький</option>
                  <option value="medium">Средний</option>
                  <option value="big">Большой</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col items-center md:w-1/2">
              <div className="w-full md:px-5">
                <label className="font-semibold text-xs" htmlFor="name">
                  Имя
                </label>
                <input
                  className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
                  type="text"
                  name="name"
                  required
                />
              </div>
              <div className="w-full md:px-5">
                <label className="font-semibold text-xs mt-3" htmlFor="phone">
                  Номер телефона
                </label>
                <input
                  className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
                  type="tel"
                  name="phone"
                  required
                />
              </div>
            </div>
          </div>

          <div className="md:px-5">
            <label className="font-semibold text-xs mt-3" htmlFor="comment">
              Комментарий
            </label>
            <input
              className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
              type="text"
              name="comment"
            />
          </div>

          <div className="flex items-center justify-center w-full md:px-5">
            <button
              className="flex items-center justify-center w-1/3 md:w-1/4 mr-6 h-12 px-6 bg-blue-600 mt-5 rounded font-semibold text-sm text-white hover:bg-blue-700 disabled:bg-slate-400"
              type="submit"
              disabled={loading || user.balance <= 0}
            >
              Забронировать
            </button>

            <button
              className="flex items-center justify-center w-1/3 md:w-1/4 h-12 px-6 bg-red-600 mt-5 rounded font-semibold text-sm text-white hover:bg-red-700"
              onClick={onClose}
              disabled={loading}
            >
              Закрыть
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default CellForm;
