import SmartboxSizesTable from "../../components/admin_components/SmartboxSizesTable/SmartboxSizesTable";

const SmartboxSizes = () => {
  return (
    <div>
      <SmartboxSizesTable />
    </div>
  );
};

export default SmartboxSizes;
