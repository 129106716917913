import { Button } from "@material-tailwind/react";
import React from "react";
import { useDispatch } from "react-redux";
import { clearUser } from "../../../redux/reducers/userSlice";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { IoExitOutline } from "react-icons/io5";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        dispatch(clearUser());
        navigate("/auth");
      })
      .catch((error) => {
        console.log("Error signing out:", error);
      });
  };

  return (
    <li className="SignOut">
      <Button
        className="font-sans font-medium uppercase transition-all text-xs py-3 rounded-lg hover:bg-blue-gray-500/10 hidden md:flex items-center gap-1 px-4"
        onClick={handleSignOut}
        variant="text"
      >
        <IoExitOutline className="w-[20px] h-[20px]" />
        Выйти
      </Button>
    </li>
  );
};

export default SignOut;
