import Nav from "../Nav/Nav";
import { MNavToggle } from "../NavToggle/NavToggle";

import classes from "./Toolbar.module.css";

import logo from "../../../assets/logos/logo.svg";
import robo from "../../../assets/robo/robo.svg";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const logo_animation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const toggle_animation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const Toolbar = ({ toggle }) => {
  return (
    <motion.nav
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className={`${classes.user_nav} flex items-center justify-between absolute top-0 left-0 right-0 z-30 w-full hd:max-w-[1700px] hd:mx-auto py-[50px] sm:py-[30px] px-[18px] sm:px-[28px] bg-transparent overflow-hidden font-manrope`}
    >
      <motion.div variants={logo_animation} custom={1}>
        <Link
          to="/"
          className={`${classes.logo} flex items-center gap-2 mr-[95px]`}
        >
          <img
            src={robo}
            alt="Robo"
            className={`${classes.robo} inline-block relative object-cover object-center w-7 h-7 sm:w-11 sm:h-11 rounded-md`}
          />
          <img
            src={logo}
            alt="SmartBox"
            className={`${classes.logo} w-[75px] sm:w-[130px]`}
          />
        </Link>
      </motion.div>
      <Nav drawer={false} />
      <MNavToggle variants={toggle_animation} custom={2} callback={toggle} />
    </motion.nav>
  );
};

export default Toolbar;
