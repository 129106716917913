import classes from "./SmartInstructions.module.css";

import robo from "../../../assets/robo/robo-ok.png";
import ok from "../../../assets/vectors/ok.svg";
import little from "../../../assets/vectors/little.svg";
import triangle from "../../../assets/vectors/triangle-dark.svg";
import indicate from "../../../assets/vectors/indicate.svg";

import present from "../../../assets/images/present.webp";
import location from "../../../assets/images/location.webp";
import security from "../../../assets/images/security.webp";
import smartboxes from "../../../assets/images/smartboxes.webp";

import number1 from "../../../assets/vectors/number-1.svg";
import number2 from "../../../assets/vectors/number-2.svg";
import number3 from "../../../assets/vectors/number-3.svg";
import number4 from "../../../assets/vectors/number-4.svg";

import { motion } from "framer-motion";

const robo_animation = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: (custom) => ({
    scale: 1,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const message_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const arrow_animation = {
  hidden: {
    y: -100,
    rotate: 90,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    rotate: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const image_animation_left = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const instruction_animation_left = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const image_animation_right = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const instruction_animation_right = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const end_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const SmartInstructions = ({ sectionRef }) => {
  return (
    <section ref={sectionRef} className={`${classes.SmartInstructions} font-manrope`}>
      <div className="hd:max-w-[1700px] hd:mx-auto">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.2, once: true }}
          className="text-center px-10"
        >
          <div className="inline-block md:ml-[280px]">
            <div className="flex justify-start mb-[-50px]">
              <motion.img
                variants={robo_animation}
                custom={1}
                src={robo}
                alt="robo"
                className="ml-[-60px] max-w-[228px]"
              />
              <motion.img
                variants={robo_animation}
                custom={2}
                src={ok}
                alt="ok"
                className="ml-[-40px]"
              />
            </div>
            <div className="relative z-[5]">
              <motion.div
                variants={message_animation}
                custom={1}
                className="bg-[#1C0448] text-white text-left inline-block py-4 sm:py-6 px-6 sm:px-8 rounded-r-[30px] sm:rounded-r-[45px] rounded-bl-[30px] sm:rounded-bl-[45px] relative"
              >
                <img
                  src={triangle}
                  alt="triangle"
                  className="absolute top-[-16px] left-0"
                />
                <p
                  className={`${classes.message_block} leading-6 sm:leading-8 text-[22px] sm:text-[28px] font-semibold`}
                >
                  Всё работает намного <br /> проще чем ты думаешь:
                </p>
              </motion.div>
              <motion.img
                variants={arrow_animation}
                custom={2}
                src={indicate}
                alt="indicate"
                className={`${classes.indicate} max-w-[200px] sm:max-w-none absolute top-5 right-6 -z-[1]`}
              />
            </div>
          </div>
        </motion.div>

        <div
          className={`${classes.bg_block} bg-[#49E1FF] rounded-t-[70px] rounded-b-[30%] md:rounded-b-[40%] overflow-hidden mt-[75px] pt-[105px] pb-[450px]`}
        >
          <div className="flex flex-col text-[#1C0448] max-w-[500px] mx-auto md:max-w-none px-6 md:px-0">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className={`${classes.first} flex justify-start items-center`}
            >
              <motion.img
                variants={image_animation_left}
                custom={1}
                src={present}
                alt="present"
                className={`${classes.instruction_image} max-w-[340px] ml-[-40px] mr-6 hidden md:block`}
              />
              <motion.div
                variants={instruction_animation_left}
                custom={2}
                className="inline-block relative mb-[-20px]"
              >
                <img
                  src={number1}
                  alt="1"
                  className="absolute top-0 left-0 mt-[-40px] scale-75 md:scale-100"
                />
                <p className="font-bold bg-white leading-8 text-[28px] py-5 px-7 md:px-10 rounded-[30px]">
                  Закажи товар <br /> из интернет магазина
                </p>
              </motion.div>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className={`${classes.second} flex justify-end md:mt-[-270px] mr-[50px]`}
            >
              <div className="flex flex-col justify-center items-end">
                <motion.img
                  variants={image_animation_right}
                  custom={1}
                  src={robo}
                  alt="robo"
                  className={`${classes.instruction_image} max-w-[370px] w-full hidden md:block`}
                />
                <motion.div
                  variants={instruction_animation_right}
                  custom={2}
                  className="inline-block relative mb-[-20px] mt-[-50px]"
                >
                  <img
                    src={number2}
                    alt="2"
                    className="absolute top-0 left-0 mt-[-37px] md:mt-[-40px] ml-[-20px] scale-75 md:scale-100"
                  />
                  <p className="font-bold bg-white leading-8 text-[28px] py-5 px-5 md:px-8 rounded-[30px] mr-[230px]">
                    Выбери способ доставки <br />{" "}
                    <span className="text-[#FB5012]">
                      самовывозом из SmartBox
                    </span>
                  </p>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className={`${classes.third} flex justify-start items-center mt-[100px] ml-[50px]`}
            >
              <motion.img
                variants={image_animation_left}
                custom={1}
                src={location}
                alt="location"
                className={`${classes.instruction_image} max-w-[340px] w-full mb-[-165px] mr-[-65px] hidden md:block`}
              />
              <motion.div
                variants={instruction_animation_left}
                custom={2}
                className="inline-block relative mb-[-20px]"
              >
                <img
                  src={number3}
                  alt="3"
                  className="absolute top-0 left-0 mt-[-43px] md:mt-[-47px] ml-[-13px] md:ml-[-10px] scale-75 md:scale-100"
                />
                <p className="font-bold bg-white leading-8 text-[28px] py-5 px-5 md:px-8 rounded-[30px]">
                  Выбери на карте ближайший <br /> для себя терминал SmartBox
                </p>
              </motion.div>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className={`${classes.fourth} flex justify-end items-center mt-[120px] mr-[150px]`}
            >
              <motion.div
                variants={instruction_animation_right}
                custom={2}
                className="inline-block relative mb-[-20px]"
              >
                <img
                  src={number4}
                  alt="4"
                  className="absolute top-0 left-0 mt-[-32px] md:mt-[-35px] ml-[-23px] md:ml-[-20px] scale-75 md:scale-100"
                />
                <p className="font-bold bg-white leading-8 text-[28px] py-5 px-5 md:px-8 rounded-[30px]">
                  Когда посылка поступит <br /> в терминал, тебе придет <br />{" "}
                  СМС с инструкциями
                </p>
              </motion.div>
              <motion.img
                variants={image_animation_right}
                custom={1}
                src={security}
                alt="security"
                className={`${classes.instruction_image} max-w-[300px] w-full scale-x-[-1] mt-[-280px] ml-[-100px] hidden md:block`}
              />
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className="text-center mt-[65px] md:mt-[135px]"
            >
              <div className="inline-block">
                <div className="flex justify-start mb-[-50px]">
                  <motion.img
                    variants={robo_animation}
                    custom={1}
                    src={robo}
                    alt="robo"
                    className={`${classes.robo_logo} ml-[-60px] max-w-[228px]`}
                  />
                  <motion.img
                    variants={robo_animation}
                    custom={2}
                    src={little}
                    alt="little"
                    className="ml-[-40px]"
                  />
                </div>
                <motion.div
                  variants={message_animation}
                  custom={1}
                  className="relative z-[5]"
                >
                  <div className="bg-[#1C0448] text-white text-left inline-block py-4 sm:py-6 px-6 sm:px-8 rounded-r-[30px] sm:rounded-r-[45px] rounded-bl-[30px] sm:rounded-bl-[45px] relative">
                    <img
                      src={triangle}
                      alt="triangle"
                      className="absolute top-[-16px] left-0"
                    />
                    <p
                      className={`${classes.message_block} leading-6 sm:leading-8 text-[22px] sm:text-[28px] font-semibold`}
                    >
                      Вот и всё! <br /> Осталось пойти <br /> и забрать посылку!
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3, once: true }}
          className="relative"
        >
          <motion.img
            variants={end_animation}
            custom={1}
            src={smartboxes}
            alt="smartboxes"
            className={`${classes.instruction_end} mx-auto mt-[-410px] max-w-[60%] w-full`}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default SmartInstructions;
