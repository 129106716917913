const PrintBtn = ({ onClick }) => {
  return (
    <td className="py-3 px-3 border-b">
      <button
        className="w-full bg-blue-400 inline-block p-1 text-white rounded-md antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize"
        onClick={onClick}
      >
        Распечатать
      </button>
    </td>
  );
};

export default PrintBtn;
