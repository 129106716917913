import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/userSlice";
import notificationSlice from "./reducers/notificationSlice";  
import transactionSlice from "./reducers/transactionSlice";
import postamatSlice from "./reducers/postamatSlice";
import adminSlice from "./reducers/adminSlice";
import companiesSlice from "./reducers/companiesSlice";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  admin: JSON.parse(localStorage.getItem("admin")) || null,
};

const store = configureStore({
  reducer: {
    user: userSlice,
    admin: adminSlice,
    notifications: notificationSlice,
    transaction: transactionSlice,
    postamat: postamatSlice,
    companies: companiesSlice,
  },
  preloadedState: initialState,
});

store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem("user", JSON.stringify(state.user));
  localStorage.setItem("admin", JSON.stringify(state.admin));
});

export default store;

// import { configureStore } from "@reduxjs/toolkit";
// import userSlice, { setUser } from "./userSlice";
// import notificationSlice from "./notificationSlice";  
// import transactionSlice from "./transactionSlice";
// import postamatSlice from "./postamatSlice";
// import adminSlice from "./adminSlice";

// const store = configureStore({
//   reducer: {
//     user: userSlice,
//     admin: adminSlice,
//     notifications: notificationSlice,
//     transaction: transactionSlice,
//     postamat: postamatSlice,
//   },
//   preloadedState: {
//     user: setUser(), // Вызываем setUser для получения данных пользователя
//   },
// }); 

// export default store;