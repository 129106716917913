import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import FullScreenLoad from "../../common_components/FullScreenLoad/FullScreenLoad";

const SignUp = ({ toggle }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Состояние для отслеживания процесса регистрации

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Устанавливаем состояние загрузки при начале регистрации

    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          name: companyName,
        },
      });
      setIsConfirm(true);
      setIsLoading(false); // Завершаем состояние загрузки после успешной регистрации
    } catch (error) {
      setError(error.message);
      setIsLoading(false); // Завершаем состояние загрузки в случае ошибки
    }
  };

  const handleConfirmation = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Устанавливаем состояние загрузки при начале подтверждения

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      setIsLoading(false); // Завершаем состояние загрузки после успешного подтверждения
      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
      setIsLoading(false); // Завершаем состояние загрузки в случае ошибки
      navigate("/dashboard");
    }
  };

  return (
    <>
      {isLoading ? (
        <FullScreenLoad /> // Показываем компонент загрузки при регистрации
      ) : isConfirm ? (
        <form
          className="flex flex-col max-w-xs w-full bg-white rounded-lg shadow-lg mt-12 p-7 sm:p-8 md:p-10 lg:p-12 mx-4 font-sans font-light"
          onSubmit={handleConfirmation}
        >
          {error && (
            <div className="Error mb-6 text-red-700 uppercase font-semibold text-xs">
              {error}
            </div>
          )}
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="confirmationCode"
            >
              Код подтверждения
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="text"
              id="confirmationCode"
              name="confirmationCode"
              required
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </div>
          <button
            className="h-12 px-6 w-full bg-blue-600 rounded font-semibold text-xs md:text-sm text-white hover:shadow-md shadow-gray-300 uppercase"
            type="submit"
          >
            Подтвердить
          </button>
        </form>
      ) : (
        <form
          className="flex flex-col max-w-xs w-full bg-white rounded-lg shadow-lg mt-12 p-7 sm:p-8 md:p-10 lg:p-12 mx-4 font-sans font-light"
          onSubmit={handleSignUp}
        >
          {error && (
            <div className="Error mb-6 text-red-700 uppercase font-semibold text-xs">
              {error}
            </div>
          )}
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="companyName"
            >
              Название компании
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="text"
              id="companyName"
              name="companyName"
              required
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="emailInput"
            >
              Эл. почта
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="email"
              id="emailInput"
              name="emailInput"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          </div>
          <div className="w-full">
            <label
              className="text-xs text-[#0b1c2c] pointer-events-none duration-500"
              htmlFor="passwordInput"
            >
              Пароль
            </label>
            <input
              className="w-full py-2 text-base text-[#0b1c2c] mb-8 border-0 border-b border-solid border-[#0b1c2c] outline-none bg-transparent"
              type="password"
              id="passwordInput"
              name="passwordInput"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </div>

          <button
            className="h-12 px-6 w-full bg-blue-600 rounded font-semibold text-xs md:text-sm text-white hover:shadow-md shadow-gray-300 uppercase"
            type="submit"
          >
            Регистрация
          </button>
          <div className="flex mt-6 justify-center text-sm">
            <p className="text-xs sm:text-sm mr-1.5 font-light">
              Уже есть аккаунт?
            </p>
            <Link
              className="text-xs sm:text-sm text-blue-400 font-medium hover:text-blue-500"
              to="#"
              onClick={toggle}
            >
              Войти
            </Link>
          </div>
        </form>
      )}
    </>
  );
};

export default SignUp;
