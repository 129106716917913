import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";

import logo from "../../../../assets/logos/logo-white.svg";
import robo from "../../../../assets/robo/funny.svg";

const AsideLogo = ({ callBack }) => {
  return (
    <div className="border-b border-white/20">
      <Link
        to="/adminpanel"
        className="flex items-center gap-4 py-6 px-8"
        onClick={callBack}
      >
        <img
          src={robo}
          alt="Robo"
          className="inline-block relative object-cover object-center w-9 h-9 rounded-md"
        />
        <img src={logo} alt="SmartBox" />
      </Link>
      <button
        onClick={callBack}
        className="middle none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-8 max-w-[32px] h-8 max-h-[32px] rounded-lg text-xs text-white hover:bg-white/10 active:bg-white/30 absolute right-0 top-0 grid rounded-br-none rounded-tl-none xl:hidden"
      >
        <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <MdClose />
        </span>
      </button>
    </div>
  );
};

export default AsideLogo;
