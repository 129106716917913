import React, { useState, forwardRef } from "react";
import NotificationList from "./NotificationList/NotificationList";
import { useSelector } from "react-redux";
import { BiSolidBell } from "react-icons/bi";
import {
  Menu,
  MenuHandler,
  MenuList,
  IconButton,
} from "@material-tailwind/react";

const ForwardedNotificationList = forwardRef((props, ref) => (
  <NotificationList {...props} forwardedRef={ref} />
));

const NotificationBell = () => {
  const notifications = useSelector((state) => state.notifications);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <li className="relative ml-1">
      <Menu open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <IconButton
            variant="text"
            className="font-sans font-medium uppercase transition-all text-md py-3 rounded-lg w-10 max-w-[40px] h-10 max-h-[40px] hover:bg-blue-gray-500/10 items-center gap-1 px-4"
          >
            <span className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
              <BiSolidBell />
            </span>
            {notifications.filter((notification) => !notification.read).length >
              0 && (
              <span className="absolute -top-4 -right-4 w-4 h-4 rounded-full bg-red-500 text-white text-xs flex items-center justify-center">
                {
                  notifications.filter((notification) => !notification.read)
                    .length
                }
              </span>
            )}
          </IconButton>
        </MenuHandler>
        <MenuList>
          <ForwardedNotificationList />
        </MenuList>
      </Menu>
    </li>
  );
};

export default NotificationBell;
