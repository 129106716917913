import Nav from "../Nav/Nav";

const Toolbar = ({ toggleAside }) => {
  return (
    <nav className="Toolbar w-100">
      <Nav toggleAside={toggleAside} />
    </nav>
  );
}

export default Toolbar;
