import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { clearUser } from "./userSlice";

const initialState = {
  cellData: [],
  completedCellData: [],
  balanceData: [],
  selectedCellCode: null,
  selectedCellData: null,
  loading: false,
  error: null,
};

export const fetchCellData = createAsyncThunk(
  "transaction/fetchCellData",
  async (user, { dispatch }) => {
    try {
      const data = {
        message: "Hello!",
      };
      const config = {
        headers: {
          Authorization: user.token,
        },
      };
      const response = await axios
        .post(
          "https://min72miot9.execute-api.us-east-1.amazonaws.com/work",
          data,
          config
        )
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            dispatch(clearUser());
          }
          console.log(error.message);
        });

      const jsonResponse = response.data.body;
      const dataArray = JSON.parse(jsonResponse);
      return dataArray;
    } catch (error) {
      console.log(error.message);
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setSelectedCellCode: (state, action) => {
      state.selectedCellCode = action.payload;
    },
    setSelectedCellData: (state, action) => {
      state.selectedCellData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCellData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCellData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.cellData = action.payload.transaction_items;
          state.completedCellData = action.payload.transaction_items.filter(
            (item) => item.status === "Completed"
          );
          state.balanceData = action.payload.balance_items;
        }
      })
      .addCase(fetchCellData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedCellCode, setSelectedCellData, setLastFetchedData } =
  transactionSlice.actions;

export default transactionSlice.reducer;
