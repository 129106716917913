import rodo from "../../../../assets/robo/funny.svg";
import triangle from "../../../../assets/vectors/triangle.svg";

const PickupCompleted = ({ userData }) => {
  return (
    <div className="pt-[85px] font-roboto w-full">
      <div className="mb-[10px]">
        <img src={rodo} alt="rodo" className="mb-4" />

        <div className="bg-[#0D79F2] text-white inline-block py-4 px-5 rounded-[20px] text-xl relative">
          <img
            src={triangle}
            alt="triangle"
            className="absolute top-[-16px] left-0"
          />
          <p className="leading-6">
            Вы успешно забрали <br /> свою посылку!👌
          </p>
        </div>
      </div>
      <div className="bg-[#EFF2F5] rounded-[20px] inline-block px-5 py-5">
        <p className="text-xl leading-6 mb-2">Дата и время получения:</p>
        <p className="text-base font-semibold">{userData.time_last_acction}</p>
      </div>
    </div>
  );
};

export default PickupCompleted;
