import { Outlet } from "react-router-dom";
import UserLayout from "../../components/user_components/UserLayout/UserLayout";

const User = () => {
  return (
    <>
      <UserLayout>
        <Outlet />
      </UserLayout>
    </>
  );
};

export default User;
