import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css";

const DateRangePicker = ({ selectedDateRange, handleValueChange }) => {
  return (
    <DatePicker
      value={[selectedDateRange.startDate, selectedDateRange.endDate]}
      startDate={selectedDateRange.startDate}
      endDate={selectedDateRange.endDate}
      onChange={handleValueChange}
      id="dateRange"
      selectsRange={true}
      monthsShown={2}
      isClearable={true}
      placeholderText="Дата"
      className="border p-2 rounded-lg shadow-sm placeholder-white text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 w-full focus:ring focus:ring-blue-200 h-full"
    />
  );
};

export default DateRangePicker;
