import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import PickupCompleted from "./PickupCompleted/PickupCompleted";
import PickupExpired from "./PickupExpired/PickupExpired";
import PickupInfo from "./PickupInfo/PickupInfo";

import FullScreenLoad from "../FullScreenLoad/FullScreenLoad";

import logo from "../../../assets/logos/logo.svg";

const SmartLinkDisplay = () => {
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [cellCode, setCellCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  const statusComponents = {
    Completed: ({ userData }) => <PickupCompleted userData={userData} />,
    Expired: () => <PickupExpired />,
    Active: ({ userData, cellCode, data, mapsUrl }) => (
      <PickupInfo
        userData={userData}
        cellCode={cellCode}
        data={data}
        mapsUrl={mapsUrl}
      />
    ),
  };

  useEffect(() => {
    const code = new URLSearchParams(location.search).get("c");
    setCellCode(code);

    const requestData = {
      cellCodeGet: code,
    };

    axios
      .post(
        `https://iuvn17dvs9.execute-api.us-east-1.amazonaws.com/test`,
        requestData
      )
      .then((response) => {
        const userDataObject = JSON.parse(response.data.body);
        setUserData(userDataObject);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке данных:", error);
        setIsLoading(false);
      });
  }, [location.search]);

  const data = {
    address: 'Бульвар Эркиндик 35, магазин "UDOMA"',
  };

  const api = "AIzaSyBrOTG10f6q_2b4rDS011y8rxSLL2vZ3mE";
  const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=${api}&q=${encodeURIComponent(
    data.address
  )}`;

  const status = userData.status; // Установка значения по умолчанию "Loading"

  // Выбор компонента на основе статуса
  const StatusComponent = statusComponents[status];

  return (
    <div className="bg-white">
      {isLoading ? (
        <FullScreenLoad />
      ) : (
        <div className="min-h-screen flex flex-col max-w-[400px] mx-auto">
          <div className="px-6 pt-5 pb-[22px] bg-[#EFF2F5] rounded-b-[30px]">
            <Link to="/">
              <img src={logo} alt="SmartBox" className="mx-auto" />
            </Link>
          </div>
          <div className="flex-grow flex-shrink flex-auto px-6">
            <StatusComponent
              userData={userData}
              cellCode={cellCode}
              data={data}
              mapsUrl={mapsUrl}
            />
          </div>
          <div className="flex flex-col justify-center items-center text-xs px-6 pt-8 pb-14">
            <div>&copy; {currentYear}</div>
            <div>
              <p className="font-semibold font-roboto">Все права защищены</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartLinkDisplay;
