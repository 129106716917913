import React from "react";
import UserCart from "../UserCart/UserCart";
import SignOut from "../SignOut/SignOut";
import NavToggle from "./NavToggle/NavToggle";
import NotificationBell from "../NotificationBell/NotificationBell";

const Nav = ({ toggleAside }) => {
  return (
    <ul className="Nav flex w-100 justify-between items-center">
      <UserCart />
      <div className="flex justify-center items-center">
        <NavToggle callBack={toggleAside} />
        <SignOut />
        <NotificationBell />
      </div>
    </ul>
  );
};

export default Nav;
