import { useState } from "react";
import SignUp from "../../components/client_components/SignUp/SignUp";
import SignIn from "../../components/client_components/SignIn/SignIn";

const ClientAuth = () => {
  const [isSignUp, setIsSignUp] = useState(false);

  const handleToggleSignUp = () => {
    setIsSignUp(!isSignUp);
  };
  return (
    <div className="absolute top-0 left-0 z-50 w-screen h-screen bg-[#1C0448] text-gray-700 flex justify-center items-center">
      {isSignUp ? (
        <SignUp toggle={handleToggleSignUp} />
      ) : (
        <SignIn toggle={handleToggleSignUp} />
      )}
    </div>
  );
};

export default ClientAuth;
