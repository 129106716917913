import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { fetchpostamatData } from "../../../redux/reducers/postamatSlice";
import "./SmartBoxTable.css";

const SmartBoxTable = () => {
  const dispatch = useDispatch();
  const { postamatData, loading, error } = useSelector(
    (state) => state.postamat
  );

  const [selectedPostId, setSelectedPostId] = useState("1");
  const [selectedOption, setSelectedOption] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPostamat, setSelectedPostamat] = useState(null);
  const [isModalSaveOpen, setIsModalSaveOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchpostamatData());
  }, [dispatch]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "free":
        return "bg-green-300";
      case "Reserve":
        return "bg-yellow-300";
      case "Time expired":
        return "bg-red-200";
      case "disable":
        return "bg-black/40";
      case "Busy":
        return "bg-blue-100";
      default:
        return "";
    }
  };

  const getModalClass = (status) => {
    switch (status) {
      case "free":
        return "bg-green-300";
      case "Reserve":
        return "bg-yellow-300";
      case "Time expired":
        return "bg-red-200";
      case "disable":
        return "bg-gray-500";
      case "Busy":
        return "bg-blue-100";
      default:
        return "";
    }
  };

  const rows = Array.from({ length: 4 }, (_, rowIndex) => (
    <tr key={rowIndex}>
      {Array.from({ length: 6 }, (_, colIndex) => {
        const cellIndex = rowIndex * 6 + colIndex;
        const postamat = postamatData[cellIndex];

        const hasCells = postamatData.some(
          (postamat) => postamat.postId === selectedPostId
        );

        if (!hasCells) {
          return (
            <td
              key={colIndex}
              className="p-2 border min-w-[90px] md:w-1/6 md:min-w-full cursor-pointer"
            >
              <div className="text-gray-400">Empty Cell</div>
            </td>
          );
        }

        if (selectedPostId && postamat && postamat.postId !== selectedPostId) {
          return null;
        }
        return (
          <td
            key={colIndex}
            className={`p-2 border min-w-[90px] md:w-1/6 md:min-w-full cursor-pointer ${
              postamat && getStatusClass(postamat.status)
            }`}
            onClick={() => handleCellClick(postamat)}
          >
            {postamat ? (
              <>
                <h3 className="text-base md:text-lg font-semibold">
                  {postamat.cellNo}
                </h3>
                <p className="mt-1 md:mt-2 text-gray-600 text-sm md:text-base">
                  {postamat.size}
                </p>
                <p className="mt-1 md:mt-2 text-gray-600 text-sm md:text-base">
                  {postamat.status}
                </p>
              </>
            ) : (
              <div className="text-gray-400">Empty Cell</div>
            )}
          </td>
        );
      })}
    </tr>
  ));

  // Обработчик для открытия модального окна и передачи данных
  const handleCellClick = (postamat) => {
    setSelectedPostamat(postamat);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="filters bg-white p-4 rounded-lg mb-3 mt-4">
        <div className="flex flex-col md:flex-row gap-4">
          <label className="flex flex-col" htmlFor="selectPostId">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Select Post ID:
            </span>
            <select
              id="selectPostId"
              name="selectPostId"
              value={selectedPostId}
              onChange={(e) => setSelectedPostId(e.target.value)}
              className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-200 text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 bg-blue-gray-900"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </label>
        </div>
      </div>

      <div className="postamat_table w-full rounded-lg shadow-sm overflow-hidden bg-white">
        <div className="smartbox_table_container w-full overflow-auto">
          <table className="table-auto w-full">
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>

      {isModalOpen && selectedPostamat && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40 p-5"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`${getModalClass(
              selectedPostamat.status
            )} relative p-4 rounded-lg w-[400px] h-[200px] md:w-[600px] md:h-[300px]`}
          >
            <div className="flex">
              <div className="w-1/2 h-1/2">
                <h3 className="text-2xl font-semibold">
                  {selectedPostamat.cellNo}
                </h3>
                <p className="mt-2 text-black text-base">
                  {selectedPostamat.size}
                </p>
                <p className="mt-2 text-black text-base">
                  {selectedPostamat.status}
                </p>
                <p className="mt-2 text-black text-base">
                  {selectedPostamat.time_reserve_start} -{" "}
                  {selectedPostamat.time_reserve_end}
                </p>
                <p className="mt-2 text-black text-base">
                  {selectedPostamat.get_time_start} -{" "}
                  {selectedPostamat.get_time_end}
                </p>
              </div>

              <div className="w-1/2 h-1/2">
                <div>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-200 text-black bg-white"
                  >
                    <option value="" disabled hidden>
                      Select option
                    </option>
                    <option value="disable">Disable</option>
                  </select>
                </div>

                <div>
                  <button
                    className="bg-green-600 text-white px-2 py-1 rounded mt-3"
                    onClick={() => setIsModalSaveOpen(true)}
                  >
                    Save
                  </button>
                </div>
              </div>

              <button
                className="absolute top-0 right-0 text-gray-700 rounded-tr-lg rounded-bl-lg hover:bg-black/10 p-2"
                onClick={() => setIsModalOpen(false)}
              >
                <RxCross2 className="w-[25px] h-[25px]" />
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalSaveOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-5"
          onClick={() => setIsModalSaveOpen(false)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="text-white bg-gray-300 flex flex-col justify-between p-4 rounded-lg w-[180px] h-[80px] md:w-[350px] md:h-[150px]"
          >
            <p className="text-2xl text-black">Save changes?</p>

            <div className="flex justify-between">
              <button
                className="bg-green-600 rounded px-2 py-1"
                onClick={() => {
                  setIsModalSaveOpen(false);
                  setIsModalOpen(false);
                }}
              >
                Save
              </button>
              <button
                className="bg-red-500 rounded px-2 py-1"
                onClick={() => setIsModalSaveOpen(false)}
              >
                Not now
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SmartBoxTable;
