import "./CellTable.css";
import { useEffect, useRef, useState } from "react";

import moment from "moment-timezone";
import { useReactToPrint } from "react-to-print";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchCellData,
  setSelectedCellCode,
  setSelectedCellData,
} from "../../../redux/reducers/transactionSlice";

import PrintBtn from "./PrintBtn/PrintBtn";
import CodePopup from "./CodePopup/CodePopup";
import TheadBox from "./TheadBox/TheadBox";
import TbodyBox from "./TbodyBox/TbodyBox";

import DateRangePicker from "../DateRangePicker/DateRangePicker";
import { filterCellData } from "../../../utils/filterUtils";
import HistoryPopup from "./HistoryPopup/HistoryPopup";
import {
  formatDateTime,
  getEndTime,
  getStartTime,
} from "../../../utils/tableUtils";

const CellTable = ({ updateCounter }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { cellData, selectedCellCode, selectedCellData } = useSelector(
    (state) => state.transaction
  );

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: moment().startOf("isoWeek").toDate(),
    endDate: moment().endOf("isoWeek").toDate(),
  });
  const [selectedPostId, setSelectedPostId] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  const [selectedCellHistory, setSelectedCellHistory] = useState([]);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCellData(user, { dispatch }));
  }, [dispatch, updateCounter, user]);

  // Группировка данных по уникальному идентификатору, например, cellCodePut
  const groupedCellData = {};
  cellData.forEach((item) => {
    const key = item.cellCodePut;
    if (
      !groupedCellData[key] ||
      item.request_time > groupedCellData[key].request_time
    ) {
      groupedCellData[key] = item;
    }
  });

  // Преобразование объекта сгруппированных данных в массив
  const latestCellData = Object.values(groupedCellData);

  const filteredData = filterCellData(
    latestCellData,
    selectedDateRange,
    selectedPostId,
    selectedSize
  );

  // Сортировка полей по времени от последнего к первому... Испарвил недочет, необходимо посмотреть что получится в будущем
  const sortedFilteredData = [...filteredData].sort(
    (a, b) => new Date(b.request_time) - new Date(a.request_time)
  );

  // Окрытие и закрытие Barcode
  const barcodeCardRef = useRef();
  const handlePrintContent = useReactToPrint({
    content: () => barcodeCardRef.current,
  });
  const handlePrintClick = (cellCodePut, postId, name) => {
    dispatch(setSelectedCellData({ cellCodePut, postId, name }));
    dispatch(setSelectedCellCode(cellCodePut.toString()));
  };
  const closePopup = () => {
    dispatch(setSelectedCellCode(null));
    dispatch(setSelectedCellData(null));
  };

  // Установка фильтра времени
  const handleValueChange = (dates) => {
    setSelectedDateRange({
      startDate: dates[0],
      endDate: dates[1],
    });
  };

  // Открытие и закрытия модального окна истории ячейки
  const loadCellHistory = (cellCodePut) => {
    const history = cellData.filter((item) => item.cellCodePut === cellCodePut);
    setSelectedCellHistory(history);
    setIsHistoryModalOpen(true); // Открываем модальное окно при загрузке истории
  };
  const closeHistoryModal = () => {
    setSelectedCellHistory([]); // Очищаем историю
    setIsHistoryModalOpen(false); // Закрываем модальное окно
  };

  return (
    <>
      <div className="filters bg-white p-4 rounded-lg mb-3">
        <div className="flex flex-col md:flex-row gap-4">
          <label className="flex flex-col md:w-1/3" htmlFor="dateRange">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Временной Промежуток:
            </span>
            <DateRangePicker
              selectedDateRange={selectedDateRange}
              handleValueChange={handleValueChange}
            />
          </label>
          <label className="flex flex-col md:w-1/3" htmlFor="boxId">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Box ID:
            </span>
            <select
              id="boxId"
              name="boxId"
              value={selectedPostId}
              onChange={(e) => setSelectedPostId(e.target.value)}
              className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-200 text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 h-full"
            >
              <option value="" className="bg-blue-gray-900">
                Все
              </option>
              <option value="1" className="bg-blue-gray-900">
                1
              </option>
              <option value="2" className="bg-blue-gray-900">
                2
              </option>
              <option value="3" className="bg-blue-gray-900">
                3
              </option>
            </select>
          </label>
          <label className="flex flex-col md:w-1/3" htmlFor="size">
            <span className="text-sm font-medium text-gray-700 mb-1">
              Размер:
            </span>
            <select
              id="size"
              name="size"
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
              className="border p-2 rounded-lg shadow-sm focus:ring focus:ring-blue-200 text-white bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 h-full"
            >
              <option value="" className="bg-blue-gray-900">
                Все
              </option>
              <option value="small" className="bg-blue-gray-900">
                Маленький
              </option>
              <option value="medium" className="bg-blue-gray-900">
                Средний
              </option>
              <option value="big" className="bg-blue-gray-900">
                Большой
              </option>
            </select>
          </label>
        </div>
      </div>

      <div className="CellTable w-full rounded-lg shadow-sm overflow-hidden bg-white">
        <div className="cell_table_container w-full overflow-auto h-[390px]">
          <table className="w-full table-auto">
            <thead className="sticky top-0 z-10 bg-white">
              <tr>
                <TheadBox>Id</TheadBox>
                <TheadBox>Код Закладки</TheadBox>
                <TheadBox>Комментарий</TheadBox>
                <TheadBox>Статус</TheadBox>
                <TheadBox>Телефон</TheadBox>
                <TheadBox>Размер</TheadBox>
                <TheadBox>Имя</TheadBox>
                <TheadBox>Начало</TheadBox>
                <TheadBox>Конец</TheadBox>
                <TheadBox>Время</TheadBox>
                <TheadBox>Распечатать</TheadBox>
              </tr>
            </thead>
            <tbody>
              {sortedFilteredData.map((item, index) => (
                <tr key={index}>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {item.postId}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {item.cellCodePut}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {item.comment}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    <span
                      className={`${
                        item.status === "Completed" ? "text-green-500" : ""
                      }${item.status === "Time expired" ? "text-red-500" : ""}`}
                    >
                      {item.status}
                    </span>
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {item.phone}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {item.size}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {item.name}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {getStartTime(item)}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {getEndTime(item)}
                  </TbodyBox>
                  <TbodyBox open={() => loadCellHistory(item.cellCodePut)}>
                    {formatDateTime(item.request_time)}
                  </TbodyBox>
                  <PrintBtn
                    onClick={() =>
                      handlePrintClick(item.cellCodePut, item.postId, item.name)
                    }
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <CodePopup
          selectedCellCode={selectedCellCode}
          selectedCellData={selectedCellData}
          barcodeCardRef={barcodeCardRef}
          closePopup={closePopup}
          handlePrintContent={handlePrintContent}
        />

        <HistoryPopup
          isOpen={isHistoryModalOpen}
          selectedCellHistory={selectedCellHistory}
          closeHistoryModal={closeHistoryModal}
        />
      </div>
    </>
  );
};

export default CellTable;
