import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import classes from "./Footer.module.css";

import logo from "../../../assets/logos/big-logo.svg";
import arrow_up from "../../../assets/vectors/arrow-up.svg";

const image_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const text_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const arrow_animation = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <motion.footer
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className="bg-[#1C0448] overflow-hidden"
    >
      <div className="max-w-[1300px] w-full mx-auto py-11 px-4 font-manrope font-medium">
        <div className="flex justify-center items-start relative">
          <motion.div variants={image_animation} custom={1}>
            <img
              src={logo}
              alt="logo"
              className="max-w-[170px] sm:max-w-[265px] w-full"
            />
          </motion.div>
          <motion.div
            variants={arrow_animation}
            custom={2}
            onClick={scrollToTop}
            className="absolute top-3 right-0 hidden sm:block"
          >
            <Link to="#">
              <img
                src={arrow_up}
                alt="arrow up"
                className="max-w-[70px] w-full"
              />
            </Link>
          </motion.div>
        </div>
        <div
          className={`${classes.footer_section} text-white flex justify-between items-end mt-20 md:mt-16`}
        >
          <motion.div
            variants={text_animation}
            custom={1}
            className={`${classes.footer_contacts} text-sm md:text-base leading-7`}
          >
            <p>
              Контактные данные: <br />
              Телефон: +996771520000 <br />
              Адрес: 8 микрорайон, дом 31б, кв. 117
            </p>
          </motion.div>
          <motion.div
            variants={text_animation}
            custom={2}
            className={`${classes.copyright_text} text-sm`}
          >
            <p>&copy; {currentYear} SmartBox™. All Rights Reserved.</p>
          </motion.div>
          <div className={`${classes.footer_toolbar} text-lg w-full relative`}>
            <ul className={`${classes.footer_nav}`}>
              <motion.li variants={text_animation} custom={3}>
                <Link to="/dashboard">Партнерам</Link>
              </motion.li>
              <motion.li variants={text_animation} custom={4}>
                <Link to="/about">О Нас</Link>
              </motion.li>
              <motion.li variants={text_animation} custom={5}>
                <Link to="/">Контакты</Link>
              </motion.li>
            </ul>
            <motion.div
              variants={arrow_animation}
              custom={2}
              onClick={scrollToTop}
              className="absolute top-[calc(50%-27px)] right-0 block sm:hidden"
            >
              <Link to="#">
                <img
                  src={arrow_up}
                  alt="arrow up"
                  className="max-w-[45px] w-full"
                />
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
