import FooterItem from "./FooterItem/FooterItem";

const Footer = () => {
  return (
    <footer className="Footer py-2">
      <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 md:justify-between">
        <p className="block antialiased font-sans text-sm leading-normal font-normal text-inherit">
          © 2023, SmartBox
        </p>
        <ul className="flex items-center gap-4">
          <FooterItem url="/">Главная</FooterItem>
          <FooterItem url="/history">История</FooterItem>
          <FooterItem url="/wallet">Баланс</FooterItem>
          <FooterItem url="/smartbox">Постамат</FooterItem>
          <FooterItem url="/about">О Нас</FooterItem>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
