import classes from "./SmartSwiper.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

import Slide from "./Slide/Slide";

import left from "../../../assets/vectors/arrow-left.svg";
import right from "../../../assets/vectors/arrow-right.svg";

import { useMediaQuery } from "@react-hook/media-query";
import { motion } from "framer-motion";

const text_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const swiper_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const SmartSwiper = () => {
  const isWideScreen = useMediaQuery("(min-width: 425px)");

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.3, once: true }}
      className="bg-[#F2F2F2] font-manrope pt-[100px] sm:pt-[200px] pb-[125px] sm:pb-[230px]"
    >
      <motion.div
        variants={text_animation}
        custom={1}
        className={`${classes.smart_swiper_title} px-[30px] max-w-[380px] sm:max-w-none mx-auto text-center`}
      >
        <h2 className="text-[#1C0448] text-[28px] sm:text-[32px] md:text-[36px] font-bold">
          Терминалы SmartBox по городу
        </h2>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.3, once: true }}
        variants={swiper_animation}
        custom={2}
        className="pb-[90px] pt-[90px] relative overflow-hidden"
      >
        <Swiper
          slidesPerView={2}
          centeredSlides={true}
          spaceBetween={30}
          loop={true}
          navigation={
            isWideScreen && {
              prevEl: ".prev",
              nextEl: ".next",
            }
          }
          pagination={{
            dynamicBullets: true,
            el: ".swiper-pagination",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            425: {
              slidesPerView: 1.7,
              spaceBetween: 25,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 35,
            },
            1600: {
              slidesPerView: 2.2,
              spaceBetween: 45,
            },
            1700: {
              slidesPerView: 2.4,
              spaceBetween: 45,
            },
            1900: {
              slidesPerView: 2.6,
              spaceBetween: 45,
            },
            2100: {
              slidesPerView: 2.8,
              spaceBetween: 45,
            },
            2300: {
              slidesPerView: 3.1,
              spaceBetween: 45,
            },
          }}
          modules={[Pagination, Navigation]}
          className={`${classes.SmartSwiper} mySwiper`}
          id="SmartSwiper"
        >
          <SwiperSlide>
            <Slide
              id="1"
              name='Магазин "UDOMA"'
              address="Бульвар Эркиндик 35"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              id="1"
              name='Магазин "UDOMA"'
              address="Бульвар Эркиндик 35"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              id="1"
              name='Магазин "UDOMA"'
              address="Бульвар Эркиндик 35"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              id="1"
              name='Магазин "UDOMA"'
              address="Бульвар Эркиндик 35"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              id="1"
              name='Магазин "UDOMA"'
              address="Бульвар Эркиндик 35"
            />
          </SwiperSlide>
          <SwiperSlide>
            <Slide
              id="1"
              name='Магазин "UDOMA"'
              address="Бульвар Эркиндик 35"
            />
          </SwiperSlide>
          {isWideScreen && (
            <>
              <div className="prev">
                <img
                  src={left}
                  alt="arrow left"
                  className="inline-block absolute left-0 top-1/2 translate-y-[-50px] z-20"
                />
              </div>
              <div className="next">
                <img
                  src={right}
                  alt="arrow right"
                  className="inline-block absolute right-0 top-1/2 translate-y-[-50px] z-20"
                />
              </div>
            </>
          )}
        </Swiper>

        <div className="swiper-pagination" />
      </motion.div>
    </motion.section>
  );
};

export default SmartSwiper;
