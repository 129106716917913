import AsideNavbar from "../AsideNavbar/AsideNavbar";
import AsideLogo from "./AsideLogo/AsideLogo";

const Aside = ({ toggleAside, open }) => {
  return (
    <aside
      className={`Aside bg-gradient-to-br from-blue-gray-800 to-blue-gray-900 ${
        open ? "" : "-translate-x-80"
      } fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 text-white`}
    >
      <AsideLogo callBack={toggleAside} />
      <AsideNavbar />
    </aside>
  );
};

export default Aside;
