import QRCode from "react-qr-code";

const UserIdModal = ({ userId, onClose }) => {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-4 rounded-lg shadow-lg text-center"
        onClick={(e) => e.stopPropagation()}
      >
        <QRCode value={userId} size={200} />
      </div>
    </div>
  );
};

export default UserIdModal;
