import { useState } from "react";
import Aside from "../Aside/Aside";
import Footer from "../Footer/Footer";
import Main from "../Main/Main";
import Toolbar from "../Toolbar/Toolbar";

const ClientLayout = ({ children }) => {
  const [asideOpen, setAsideOpen] = useState(false);

  function toggleAside() {
    setAsideOpen(!asideOpen);
  }
  return (
    <div className="h-full">
      <Aside toggleAside={toggleAside} open={asideOpen} />
      <Main>
        <Toolbar toggleAside={toggleAside} />
        <div className="Child">{children}</div>
        <Footer />
      </Main>
    </div>
  );
};

export default ClientLayout;
