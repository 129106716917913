import moment from "moment-timezone";

export function formatDateTime(dateTime) {
  return moment(dateTime).format("YYYY-MM-DD HH:mm");
}

export function getStartTime(item) {
  return item.status === "Reserve"
    ? formatDateTime(item.time_reserve_start)
    : item.status === "Busy"
    ? formatDateTime(item.get_time_start)
    : "------";
}
export function getEndTime(item) {
  return item.status === "Reserve"
    ? formatDateTime(item.time_reserve_end)
    : item.status === "Busy"
    ? formatDateTime(item.get_time_end)
    : "------";
}