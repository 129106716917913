import React from "react";
import { FaWallet } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { BsClockHistory, BsTable } from "react-icons/bs";
import { IoExitOutline } from "react-icons/io5";
import AsideNavItem from "./AsideNavItem/AsideNavItem";
import AsideSignOut from "./AsideSignOut/AsideSignOut";
import { useSelector } from "react-redux";

const AsideNavbar = ({ toggleAside }) => {
  const user = useSelector((state) => state.user);
  const path = "dashboard";

  return (
    <div className="m-4">
      <ul className="mb-4 flex flex-col gap-1">
        <AsideNavItem url={`/${path}`} toggle={toggleAside}>
          <AiFillHome className="mr-3 w-[20px] h-[20px]" />
          <p className="middle none font-sans font-bold">Главная</p>
        </AsideNavItem>
        {user && user.status === "approve" && (
          <>
            <AsideNavItem url={`/${path}/table`} toggle={toggleAside}>
              <BsTable className="mr-3 w-[20px] h-[20px]" />
              <p className="middle none font-sans font-bold">Таблица</p>
            </AsideNavItem>

            <AsideNavItem url={`/${path}/history`} toggle={toggleAside}>
              <BsClockHistory className="mr-3 w-[20px] h-[20px]" />
              <p className="middle none font-sans font-bold">История</p>
            </AsideNavItem>
            <AsideNavItem url={`/${path}/wallet`} toggle={toggleAside}>
              <FaWallet className="mr-3 w-[20px] h-[20px]" />
              <p className="middle none font-sans font-bold">Баланс</p>
            </AsideNavItem>
          </>
        )}
      </ul>
      <ul className="mb-4 flex flex-col gap-1 md:hidden">
        <li className="w-full mx-3.5 mt-4 mb-2">
          <p className="block antialiased font-sans text-sm leading-normal text-white font-black uppercase opacity-75">
            Auth pages
          </p>
        </li>
        <AsideSignOut>
          <IoExitOutline className="mr-3 w-[20px] h-[20px]" /> Выйти
        </AsideSignOut>
      </ul>
    </div>
  );
};

export default AsideNavbar;
