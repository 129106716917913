import AdminSignIn from "../../components/admin_components/AdminSignIn/AdminSignIn";

const AdminAuth = () => {
  return (
    <div className="absolute top-0 left-0 z-50 w-screen h-screen bg-[#1C0448] text-gray-700 flex justify-center items-center">
      <AdminSignIn />
    </div>
  );
}

export default AdminAuth;
