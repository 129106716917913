import { Outlet } from "react-router-dom";
import AdminLayout from "../../components/admin_components/AdminLayout/AdminLayout";

const Admin = () => {
  return (
    <div className="h-full">
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </div>
  );
};

export default Admin;
