const TheadBox = ({ children }) => {
  return (
    <th className="border-b py-3 px-3 text-left">
      <p className="block antialiased text-xs font-bold uppercase whitespace-nowrap">
        {children}
      </p>
    </th>
  );
};

export default TheadBox;
