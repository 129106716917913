import QRCode from "react-qr-code";
import Iframe from "react-iframe";

import rodo from "../../../../assets/robo/happy.svg";
import triangle from "../../../../assets/vectors/triangle.svg";

const PickupInfo = ({ userData, cellCode, data, mapsUrl }) => {
  return (
    <div className="pt-[55px] font-roboto w-full">
      <div className="mb-[10px]">
        <img src={rodo} alt="rodo" className="mb-4" />

        <div className="bg-[#0D79F2] text-white inline-block py-4 px-5 rounded-[20px] text-xl relative">
          <img
            src={triangle}
            alt="triangle"
            className="absolute top-[-16px] left-0"
          />
          <p className="mb-4">Ваша посылка прибыла!😍</p>
          <p className="leading-6">
            Можете забрать посылку <br /> до {userData.transaction_end}
          </p>
        </div>
      </div>

      <div className="bg-[#EFF2F5] rounded-[20px] py-10 px-6 mb-[10px]">
        <h3 className="text-[#0D0221] font-semibold text-xl text-center">
          Код ячейки:
        </h3>
        <div className="bg-[#0D0221] text-[#FB5012] text-lg w-[190px] mx-auto text-center tracking-[5px] rounded-full px-4 pt-[13px] pb-[11px] my-6">
          <p>{cellCode}</p>
        </div>
        <div className="w-[190px] mx-auto mb-6">
          <p className="text-[#0D0221] text-sm leading-4 text-center">
            Введите код или отсканируйте QR-код внизу на постамате
          </p>
        </div>
        <div className="mb-3">
          <QRCode value={cellCode} size={215} className="mx-auto" />
        </div>
      </div>

      <div className="bg-[#EFF2F5] rounded-[20px] pt-10 pb-6 px-5">
        <h3 className="text-[#0D0221] font-semibold text-xl text-center">
          Адрес:
        </h3>
        <div className="w-[190px] mx-auto mt-5 mb-7">
          <p className="text-[#0D0221] text-sm leading-4 text-center">
            {data.address}
          </p>
        </div>
        <div className="mt-2">
          <Iframe url={mapsUrl} width="100%" height="250px" />
        </div>
      </div>
    </div>
  );
};

export default PickupInfo;
