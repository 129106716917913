import React from "react";
import { Link } from "react-router-dom";
import notfound from "../../assets/notfound.webp";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-400 to-pink-500 px-3">
      <div className="bg-white p-8 rounded-lg shadow-md text-center max-w-md w-full">
        <img src={notfound} alt="404" className="w-48 mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-gray-800 mb-2">
          Oops! Page not found
        </h1>
        <p className="text-gray-600 mb-4">
          The page you are looking for does not exist.
        </p>
        <Link to="/" className="text-purple-500 hover:underline">
          Go back home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
