import Nav from "../Nav/Nav";
import "./Drawer.css";

const Drawer = ({ open, toggle }) => {
  const drawerClassNames = `Drawer ${open ? "open" : ""}`;

  return (
    <div className={drawerClassNames}>
      <div onClick={toggle} className="backdrop"></div>
      <div className="content">
        <Nav drawer={true} />
      </div>
    </div>
  );
};

export default Drawer;
