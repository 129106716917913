import classes from "./NavItem.module.css";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { forwardRef } from "react";

const NavItem = forwardRef(({ url, children }, ref) => {
  return (
    <li
      ref={ref}
      className={`${classes.nav_item} w-full p-4 text-xl bg-gray-900/50 mt-1`}
    >
      <Link to={url} className="text-white text-[18px] font-medium">
        {children}
      </Link>
    </li>
  );
});

export default NavItem;

export const MNavItem = motion(NavItem);
