import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import classes from "./BoxesInfo.module.css";

import small from "../../../../assets/images/small.webp";
import medium from "../../../../assets/images/medium.webp";
import big from "../../../../assets/images/big.webp";

import { useMediaQuery } from "@react-hook/media-query";

const text_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const box_animation = {
  hidden: {
    y: -100,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};
const card_animation = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: (custom) => ({
    scale: 1,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

const BoxesInfo = () => {
  const isWideScreen = useMediaQuery("(min-width: 900px)");

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2, once: true }}
      className="px-[50px]"
    >
      <motion.div
        variants={text_animation}
        custom={1}
        className={`${classes.title} mx-auto text-center`}
      >
        <h2 className="text-white text-[28px] sm:text-[32px] md:text-[36px] font-bold">
          SmartBox имеет 3 вида бокса
        </h2>
      </motion.div>
      <div
        className={`${classes.boxes_info_container} flex justify-between items-center pt-[78px] mb-[-260px] max-w-[1070px] w-full mx-auto`}
      >
        {isWideScreen ? (
          <>
            <motion.div
              variants={box_animation}
              custom={3}
              className={`${classes.box} w-[28.5%] relative`}
            >
              <img src={medium} alt="medium" />
              <motion.div
                variants={card_animation}
                custom={4}
                className={`${classes.box_info} bg-white w-[120%] h-[44.5%] px-6 py-5 lg:px-9 lg:py-8 border-[0.725px] border-[#1C0448] rounded-[40px] absolute -bottom-1 -left-[10%] z-20`}
              >
                <div>
                  <h2 className="text-[#1C0448] text-2xl font-semibold mb-1">
                    Средний
                  </h2>
                  <p className="text-[#1C0448] text-base leading-[20px]">
                    Подходит для средних покупок, сумки и т.п.
                  </p>
                </div>
                <div className={`${classes.order_btn} text-center mt-[15%]`}>
                  <Link
                    to="#"
                    className="text-[#1C0448] text-2xl font-semibold border-[0.725px] border-[#1C0448] px-[30px] pt-[20px] pb-[22px] rounded-[100px] inline-block"
                  >
                    Заказать
                  </Link>
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              variants={box_animation}
              custom={4}
              className={`${classes.box} w-[28.5%] relative`}
            >
              <img src={big} alt="big" />
              <motion.div
                variants={card_animation}
                custom={5}
                className={`${classes.box_info} bg-white w-[120%] h-[44.5%] px-6 py-5 lg:px-9 lg:py-8 border-[0.725px] border-[#FB5012] rounded-[40px] absolute -bottom-1 -left-[10%] z-20`}
              >
                <div>
                  <h2 className="text-[#1C0448] text-2xl font-semibold mb-1">
                    Большой
                  </h2>
                  <p className="text-[#1C0448] text-base leading-[20px]">
                    Вмещает в себя большие покупки, ноутбуки т.п.
                  </p>
                </div>
                <div className={`${classes.order_btn} text-center mt-[15%]`}>
                  <Link
                    to="#"
                    className="text-[#FB5012] text-2xl font-semibold border-[0.725px] border-[#FB5012] px-[30px] pt-[20px] pb-[22px] rounded-[100px] inline-block"
                  >
                    Заказать
                  </Link>
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              variants={box_animation}
              custom={2}
              className={`${classes.box} w-[28.5%] relative`}
            >
              <img src={small} alt="small" />
              <motion.div
                variants={card_animation}
                custom={3}
                className={`${classes.box_info} bg-white w-[120%] h-[44.5%] px-6 py-5 lg:px-9 lg:py-8 border-[0.725px] border-[#0D79F2] rounded-[40px] absolute -bottom-1 -left-[10%] z-20`}
              >
                <div>
                  <h2 className="text-[#1C0448] text-2xl font-semibold mb-1">
                    Маленький
                  </h2>
                  <p className="text-[#1C0448] text-base leading-[20px]">
                    Подходит для небольших покупок, не дорогой ценник
                  </p>
                </div>
                <div className={`${classes.order_btn} text-center mt-[15%]`}>
                  <Link
                    to="#"
                    className="text-[#0D79F2] text-2xl font-semibold border-[0.725px] border-[#0D79F2] px-[30px] pt-[20px] pb-[22px] rounded-[100px] inline-block"
                  >
                    Заказать
                  </Link>
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : (
          <>
            <motion.div
              variants={box_animation}
              custom={2}
              className={`${classes.box} w-[28.5%] relative`}
            >
              <img src={medium} alt="medium" />
              <motion.div
                variants={card_animation}
                custom={3}
                className={`${classes.box_info} bg-white w-[120%] h-[44.5%] px-6 py-5 lg:px-9 lg:py-8 border-[0.725px] border-[#1C0448] rounded-[40px] absolute -bottom-1 -left-[10%] z-20`}
              >
                <div>
                  <h2 className="text-[#1C0448] text-2xl font-semibold mb-1">
                    Средний
                  </h2>
                  <p className="text-[#1C0448] text-base leading-[20px]">
                    Подходит для средних покупок, сумки и т.п.
                  </p>
                </div>
                <div className={`${classes.order_btn} text-center mt-[15%]`}>
                  <Link
                    to="#"
                    className="text-[#1C0448] text-2xl font-semibold border-[0.725px] border-[#1C0448] px-[30px] pt-[20px] pb-[22px] rounded-[100px] inline-block"
                  >
                    Заказать
                  </Link>
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              variants={box_animation}
              custom={4}
              className={`${classes.box} w-[28.5%] relative`}
            >
              <img src={big} alt="big" />
              <motion.div
                variants={card_animation}
                custom={5}
                className={`${classes.box_info} bg-white w-[120%] h-[44.5%] px-6 py-5 lg:px-9 lg:py-8 border-[0.725px] border-[#FB5012] rounded-[40px] absolute -bottom-1 -left-[10%] z-20`}
              >
                <div>
                  <h2 className="text-[#1C0448] text-2xl font-semibold mb-1">
                    Большой
                  </h2>
                  <p className="text-[#1C0448] text-base leading-[20px]">
                    Вмещает в себя большие покупки, ноутбуки т.п.
                  </p>
                </div>
                <div className={`${classes.order_btn} text-center mt-[15%]`}>
                  <Link
                    to="#"
                    className="text-[#FB5012] text-2xl font-semibold border-[0.725px] border-[#FB5012] px-[30px] pt-[20px] pb-[22px] rounded-[100px] inline-block"
                  >
                    Заказать
                  </Link>
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              variants={box_animation}
              custom={6}
              className={`${classes.box} w-[28.5%] relative`}
            >
              <img src={small} alt="small" />
              <motion.div
                variants={card_animation}
                custom={7}
                className={`${classes.box_info} bg-white w-[120%] h-[44.5%] px-6 py-5 lg:px-9 lg:py-8 border-[0.725px] border-[#0D79F2] rounded-[40px] absolute -bottom-1 -left-[10%] z-20`}
              >
                <div>
                  <h2 className="text-[#1C0448] text-2xl font-semibold mb-1">
                    Маленький
                  </h2>
                  <p className="text-[#1C0448] text-base leading-[20px]">
                    Подходит для небольших покупок, не дорогой ценник
                  </p>
                </div>
                <div className={`${classes.order_btn} text-center mt-[15%]`}>
                  <Link
                    to="#"
                    className="text-[#0D79F2] text-2xl font-semibold border-[0.725px] border-[#0D79F2] px-[30px] pt-[20px] pb-[22px] rounded-[100px] inline-block"
                  >
                    Заказать
                  </Link>
                </div>
              </motion.div>
            </motion.div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default BoxesInfo;
