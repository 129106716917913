import CompletedTable from "../../components/admin_components/CompletedTable/CompletedTable";

const AdminHistory = () => {
  return (
    <div>
      <CompletedTable />
    </div>
  );
};

export default AdminHistory;
